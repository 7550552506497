<template>
	<div class="card container-calendar-list" :style="{ borderColor: card.data[0].colors.color }">
		<div class="container-day-now-list" :class="[new Date().getDate() == card.date.getDate() ? 'es-hoy' : '']">
			<p class="day-number m-0 day-calendar-tournament">
				{{ date.day }} <span>{{ monthText.name }}</span>
			</p>
			<p class="day-text m-0">
				{{ new Date().getDate() == card.date.getDate() ? "Hoy" : dayText.name }}
			</p>
		</div>
		<div>
			<div
				class="tournament-item-task"
				v-for="(item, index) in card.data"
				:key="index"
				@click="selectTask(item)"
				:style="{ '--bg-hover': item.colors.color1 }"
			>
				<div
					class="circle-day"
					:style="{
						background: layout.skin === 'light' ? `${item.colors.color}` : `${item.colors.color1}`,
					}"
				/>
				<div class="">
					<div class="container-title">
						<p
							class="m-0 title"
							:style="{
								color: layout.skin === 'light' ? `${item.colors.color}` : `${item.colors.color1}`,
							}"
						>
							Torneo | {{ item.title }}
						</p>
						<div v-if="item.stage_id == 3" class="final img-list">
							<img class="" src="../../../../assets/images/tournament/cup.svg" alt="" />
						</div>
					</div>
					<p class="time font-weight-bolder">{{ item.time }} - {{ item.battle }}</p>
				</div>
			</div>
		</div>
		<modal-day-calendar
			:task-view="taskView"
			:open-modal-task="openModalTask"
			@closeModal="closeModal"
			v-if="taskView !== null"
		/>
	</div>
</template>

<script>
import { mapState } from "vuex";
import ModalDayCalendar from "@/views/amg/tournaments/calendar/ModalDayCalendar";
import { backgroundColor } from "echarts/lib/theme/dark";

export default {
	name: "DayCalendarListItem",
	components: { ModalDayCalendar },
	props: {
		card: {
			type: Object,
		},
	},
	data() {
		return {
			date: {
				day: 0,
				month: 0,
				year: 0,
			},
			arrayDays: [
				{
					name: "Domingo",
					id: 0,
				},
				{
					name: "Lunes",
					id: 1,
				},
				{
					name: "Martes",
					id: 2,
				},
				{
					name: "Miercoles",
					id: 3,
				},
				{
					name: "Jueves",
					id: 4,
				},
				{
					name: "Viernes",
					id: 5,
				},
				{
					name: "Sabado",
					id: 6,
				},
			],
			arrayMonths: [
				{
					name: "Enero",
					id: 1,
				},
				{
					name: "Febrero",
					id: 2,
				},
				{
					name: "Marzo",
					id: 3,
				},
				{
					name: "Abril",
					id: 4,
				},
				{
					name: "Mayo",
					id: 5,
				},
				{
					name: "Junio",
					id: 6,
				},
				{
					name: "Julio",
					id: 7,
				},
				{
					name: "Agosto",
					id: 8,
				},
				{
					name: "Setiembre",
					id: 9,
				},
				{
					name: "Octubre",
					id: 10,
				},
				{
					name: "Noviembre",
					id: 11,
				},
				{
					name: "Diciembre",
					id: 12,
				},
			],
			dayText: null,
			monthText: null,
			openModalTask: false,
			taskView: null,
		};
	},
	computed: {
		...mapState("appConfig", ["layout"]),
	},
	created() {
		this.date.day = this.card.date.getDate();
		this.date.month = this.card.date.getMonth();
		this.date.year = this.card.date.getFullYear();
		this.dayText = this.arrayDays.find((day) => day.id == this.card.date.getDay());
		this.monthText = this.arrayMonths.find((item) => item.id == this.card.date.getMonth());
	},
	methods: {
		selectTask(task) {
			this.openModalTask = true;
			this.taskView = task;
		},
		closeModal() {
			this.taskView = null;
			this.openModalTask = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.circle-day {
	height: 7px !important;
	width: 7px !important;
	border-radius: 100%;
}
.container-calendar-list {
	display: grid;
	grid-template-columns: 2fr 9fr;
	margin-bottom: 0.8rem;
	border-left: 4px solid #f8f8f8;
	@media screen and (min-width: 2500px) {
		grid-template-columns: 1fr 10fr;
	}
}
.tournament-item-task {
	padding: 1rem;
	transition: 0.3s all ease-in-out;
	display: flex;
	align-items: flex-start;
	cursor: pointer;

	@media screen and (min-width: 600px) {
		padding: 1.5rem 3rem;
	}
	&:hover {
		background: var(--bg-hover, #f8f8f8);
	}
	.title {
		font-size: 13px;
		font-weight: 500;
		@media screen and (min-width: 600px) {
			font-size: 14px;
		}
		@media screen and (min-width: 900px) {
			font-size: 16px;
		}
	}
	.time {
		font-size: 11px;
		margin: 0;
		@media screen and (min-width: 600px) {
			font-size: 12.6px;
			margin-top: 0.5rem;
		}
		@media screen and (min-width: 900px) {
			font-size: 14px;
		}
	}
	.circle-day {
		margin-right: 0.5rem;
		margin-top: 0.5rem;
	}

	.container-title {
		position: relative;
		.final {
			position: absolute;
			top: 0;
			right: -40px;
			height: 30px;
			width: 30px;
			img {
				height: 11px;
				width: 11px;
				object-fit: cover;
				@media screen and (min-width: 900px) {
					top: -3px;
					right: -40px;
					height: 30px;
					width: 30px;
				}
			}
		}
	}
	.img-list {
		img {
			height: 20px !important;
			width: 20px !important;
			top: 0 !important;
		}
	}
	&:not(:last-child) {
		border-bottom: 2px solid #c4c4c4;
	}
}
.dark-layout .tournament-item-task {
	&:hover {
		background: #161d31;
	}
}
.container-day-now-list {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-right: 2px solid #c4c4c4;
	@media screen and (min-width: 600px) {
		padding: 1.5rem 3rem;
	}
	.day-number {
		font-size: 12px;
		font-weight: bolder;
		@media screen and (min-width: 600px) {
			font-size: 14px;
		}
		@media screen and (min-width: 900px) {
			font-size: 16px;
		}
	}
	.day-text {
		font-size: 11px;
		display: flex;
		align-items: flex-start;
		@media screen and (min-width: 600px) {
			font-size: 12px;
		}
		@media screen and (min-width: 900px) {
			font-size: 14px;
		}
	}
}
.es-hoy {
	.day-number,
	.day-text {
		color: #3c98f1;
		font-weight: 600;
	}
	.day-number {
	}
}
.day-calendar-tournament {
	display: flex;
	flex-direction: column;
	align-items: center;
	@media screen and (min-width: 600px) {
		flex-direction: row;
		span {
			margin-left: 0.5rem;
		}
	}
}
</style>
