<template>
  <validation-observer tag="div" ref="form">
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group
          label="Tipo de clasificación"
          :label-for="`classification-type`"
        >
          <b-form-select
            v-model="front.classification_type"
            :options="optionsClassificationType"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" v-if="front.classification_type == 1">
        <b-form-group label="Inscripción" label-for="precio">
          <b-input-group size="md" id="precio">
            <template #prepend>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="front.currency_id == 1 ? '$ ' : 'S/'"
                :variant="
                  layout.skin == 'dark'
                    ? 'outline-gray-1000'
                    : 'outline-white-1000'
                "
              >
                <b-dropdown-item @click="setStageCurrency(1)">
                  $
                </b-dropdown-item>
                <b-dropdown-item @click="setStageCurrency(2)">
                  S/
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <money
              class="form-control text-right"
              v-bind="money"
              v-model="front.registration_cost"
            ></money>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Tipo" label-for="type">
          <b-form-select
            v-model="front.type_front_id"
            :options="tournamentTypes"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Gallos" label-for="rooster-quantity">
          <b-form-select
            id="rooster-quantity"
            v-model="front.rooster_quantity"
            :options="roosterQuantityOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Gallos por Llevar" label-for="rooster-quantity">
          <b-form-select
            id="rooster-quantity-to-carry"
            v-model="front.rooster_quantity_to_carry"
            :options="roosterQuantityOptionsToCarry"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TournamentService from "../tournament.service";
import { Money } from "v-money";
export default {
  props: {
    frontObject: {
      type: Object,
    },
  },
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    Money,
  },
  computed: {
    layout() {
      return this.$store.getters["appConfig/layout"];
    },
  },
  async mounted() {
    await this.getTournamentTypes();
    this.front = { ...this.frontObject };
    this.front.currency_id =
      this.front.classification_type == 1 ? this.front.currency_id || 2 : null;
    this.front.registration_cost =
      this.front.classification_type == 1
        ? this.front.registration_cost || 0
        : null;
  },
  data() {
    return {
      front: {},
      hours: [],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      optionsClassificationType: [
        { id: 1, name: "Inscripción" },
        { id: 2, name: "Clasificación" },
      ],
      roosterQuantityOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      roosterQuantityOptionsToCarry: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
      ],
      rooster_quantity: 0,
      rooster_quantity_to_play: 0,
      responsables: [],
      tournamentTypes: [],
    };
  },
  methods: {
    async updateTournamentFront() {
      const succesfull = await this.$refs.form.validate();
      if (succesfull) {
        try {
          this.front.created_by = this.currentUser.user_id;
          await TournamentService.updateTournamentFront(this.front);
          this.closeModal();
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Frente actualizado!",
                icon: "CheckIcon",
                text: "Frente actualizado correctamente",
                variant: "success",
              },
            },
            {
              position: "top-right",
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    fixedCost(front) {
      if (front.registration_cost) {
        front.registration_cost = Number(front.registration_cost).toFixed(2);
      }
    },
    closeModal() {
      this.$emit("closeModal", null);
    },
    setStageCurrency(currency, rooster) {
      this.$set(rooster, "currency_id", currency);
    },
    getTournamentTypes() {
      axios.get("/api/tournament/get-tournaments-types").then((response) => {
        this.tournamentTypes = response.data;
      });
    },
  },
};
</script>
