var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.tasks),function(time,index){return _c('div',{key:index,staticClass:"day day-border pt-0",class:[
            _vm.day.monthName.id === new Date().getMonth() + 1
                ? 'month-current'
                : '',
            _vm.day.monthSelect ? 'es-hoy' : '',
            _vm.day.idArray < 28
                ? 'day-border'
                : 'day-border border-bottom-none',
            _vm.day.idArray === 6 ||
            _vm.day.idArray === 13 ||
            _vm.day.idArray === 20 ||
            _vm.day.idArray === 27 ||
            _vm.day.idArray === 34
                ? 'border-right-none'
                : ''
        ]},[_c('div',{staticClass:"container-day-extend"},_vm._l((time.tasks),function(card,key){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:key,staticClass:"tournament-item-task",style:({
                    background:
                        _vm.layout.skin === 'light'
                            ? ("" + (card.colors.color1))
                            : ("" + (card.colors.color))
                }),attrs:{"title":card.title},on:{"click":function($event){return _vm.selectTask(card)}}},[_c('div',{staticClass:"circle-day",style:({
                        background:
                            _vm.layout.skin === 'light'
                                ? ("" + (card.colors.color))
                                : ("" + (card.colors.color1))
                    })}),_c('span',{staticClass:"text-title-day",style:({
                        color:
                            _vm.layout.skin === 'light'
                                ? ("" + (card.colors.color))
                                : ("" + (card.colors.color1))
                    })},[_vm._v(" "+_vm._s(card.title)+" ")])])}),0)])}),(_vm.taskView !== null)?_c('modal-day-calendar',{attrs:{"task-view":_vm.taskView,"open-modal-task":_vm.openModalTask},on:{"closeModal":_vm.closeModal}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }