<template>
    <div>
        <div
            :class="[
                day.monthName.id === new Date().getMonth() + 1
                    ? 'month-current'
                    : '',
                day.monthSelect ? 'es-hoy' : '',
                day.idArray < 28
                    ? 'day-border'
                    : 'day-border border-bottom-none',
                day.idArray === 6 ||
                day.idArray === 13 ||
                day.idArray === 20 ||
                day.idArray === 27 ||
                day.idArray === 34
                    ? 'border-right-none'
                    : ''
            ]"
            class="day day-border pt-0"
            v-for="(time, index) in tasks"
            :key="index"
        >
            <div class="container-day-extend">
                <div
                    v-for="(card, key) in time.tasks"
                    :key="key"
                    class="tournament-item-task"
                    :style="{
                        background:
                            layout.skin === 'light'
                                ? `${card.colors.color1}`
                                : `${card.colors.color}`
                    }"
                    @click="selectTask(card)"
                    v-b-tooltip.hover :title="card.title"
                >
                    <div
                        class="circle-day"
                        :style="{
                            background:
                                layout.skin === 'light'
                                    ? `${card.colors.color}`
                                    : `${card.colors.color1}`
                        }"
                    />
                    <span
                        class="text-title-day"
                        :style="{
                            color:
                                layout.skin === 'light'
                                    ? `${card.colors.color}`
                                    : `${card.colors.color1}`
                        }"
                    >
                        {{ card.title }}
                    </span>
                </div>
            </div>
        </div>
        <modal-day-calendar
            v-if="taskView !== null"
            :task-view="taskView"
            :open-modal-task="openModalTask"
            @closeModal="closeModal"
        />
    </div>
</template>

<script>
import { mapState } from "vuex";
import ModalDayCalendar from "@/views/amg/tournaments/calendar/ModalDayCalendar";

export default {
    name: "DayWeekCalendarItem",
    components: { ModalDayCalendar },
    props: {
        day: {
            type: Object,
            default: () => {}
        },
        times: {
            type: Array
        },
        week: {
            type: Array,
        }
    },
    data() {
        return {
            tasks: [],
            dayNow: new Date(),
            openModalTask: false,
            taskView: null
        };
    },
    computed: {
        ...mapState("appConfig", ["layout"])
    },
    created() {
      this.initCalendarWeek()
    },
    methods: {
        initCalendarWeek() {
            this.tasks = this.times;
            this.tasks = this.times.map(item => {
                const tareas = this.day.data;
                const tasks = tareas.filter(task => {
                    if (
                        parseInt(task.time1) == item.hour &&
                        (parseInt(task.time2) == 30 ? 1 : 0) == item.minute
                    ) {
                        return task;
                    }
                });
                return {
                    ...item,
                    tasks
                };
            });
        },
        selectTask(task) {
            this.openModalTask = true;
            this.taskView = task;
        },
        closeModal() {
            this.taskView = null;
            this.openModalTask = false;
        }
    },
    watch:{
        week() {
            this.initCalendarWeek()
        }
    }
};
</script>

<style lang="scss" scoped>
.day {
    height: 40px;
}
.container-day-extend{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-title-day{
        //margin-top: -0.1rem !important;
}
</style>
