<template>
	<div class="container-calendar-view">
		<div class="container-leyenda">
			<div class="card p-2" :style="{ marginBottom: 0 }">
				<h4 class="mb-1">ETAPAS:</h4>
				<div class="mb-2">
					<div class="p-0">
						<div
							class="container-name-stage justify-content-start"
							v-for="(stage, index) in arrayStages"
							:key="`stage-${index}`"
						>
							<div class="circle-stages">{{ index + 1 }}</div>
							<span>{{ stage }}</span>
						</div>
					</div>
				</div>

				<h4 class="mb-1">ESTADOS DE LOTES</h4>
				<div class="d-flex align-items-center mt-50">
					<div
						class="circle"
						:style="{
							background: 'rgb(174, 177, 180)',
						}"
					></div>
					<p class="mb-0" :style="{ marginLeft: '.4rem' }">INACTIVA</p>
				</div>
				<div class="d-flex align-items-center mt-50">
					<div
						class="circle"
						:style="{ background: 'rgb(234, 84, 85)', height: '12px', width: '12px', borderRadius: '50%' }"
					></div>
					<p class="mb-0" :style="{ marginLeft: '.4rem' }">PENDIENTE</p>
				</div>
				<div class="d-flex align-items-center mt-50">
					<div
						class="circle"
						:style="{ background: 'rgb(40, 199, 111)', height: '12px', width: '12px', borderRadius: '50%' }"
					></div>
					<p class="mb-0" :style="{ marginLeft: '.4rem' }">REALIZADA</p>
				</div>
			</div>
		</div>
		<div class="card" :style="{ marginBottom: 0 }">
			<div class="mb-2 mt-2">
				<div class="container-tabs-calendar mr-2 ml-2">
					<div class="d-flex align-items-center justify-content-between">
						<h2 class="m-0 font-weight-bolder">
							{{ yearCurrent }}
							{{ arrayMonths.find((item) => item.id === monthtCurrent).name }}
						</h2>
					</div>
					<div class="grid-icons-arrow d-flex icons-container">
						<template v-if="tabCalendar === 1">
							<div>
								<feather-icon
									class="icon-arrow-calendar"
									@click="setDateArrow(-1, dateNow)"
									icon="ChevronLeftIcon"
									size="20"
								/>
								<feather-icon
									class="icon-arrow-calendar"
									@click="setDateArrow(1, dateNow)"
									icon="ChevronRightIcon"
									size="20"
								/>
							</div>
							<b-button variant="outline-primary" @click="setDateArrow(0, new Date())" class="ml-1">
								<span class="text-nowrap ml-2 mr-2">Hoy</span>
							</b-button>
						</template>
					</div>
				</div>

				<div class="container-calendar-total calendario-lotes">
					<div>
						<template v-if="tabCalendar === 1">
							<div class="container-grid-calendar content-days mt-1">
								<p
									v-for="(day, key) in arrayDays"
									:key="key"
									class="day font-small-2 day-item font-weight-bolder"
									:style="{ color: 'red' }"
								>
									{{ widthScreen < 600 ? day.name.substr(0, 3).toUpperCase() : day.name }}
								</p>
							</div>
							<div
								v-for="(semana, key) in calendar"
								:key="key"
								class="container-grid-calendar grid-semana"
							>
								<day-calendar
									v-for="(day, key) in semana"
									:key="`up-${key}`"
									:day="day"
									:height="height"
									:open="open"
									@openCard="openCard"
									@setSelectDay="setSelectDay"
								/>
							</div>
						</template>

						<template v-if="tabCalendar === 2">
							<div class="container-grid-calendar container-by-week content-days mt-1">
								<p
									class="day font-small-2 day-item"
									:style="{
										color: layout.skin === 'light' ? '#656565' : '#f4f4f4',
										background: 'none',
									}"
								></p>
								<p
									v-for="(day, key) in arrayDays"
									:key="key"
									class="day font-small-2 day-item"
									:style="{
										color: layout.skin === 'light' ? '#656565' : '#f4f4f4',
										background: 'none',
									}"
								>
									{{ widthScreen < 600 ? day.name.substr(0, 3).toUpperCase() : day.name }}
								</p>
							</div>

							<div v-for="(semana, key) in calendarWeek" :key="key">
								<day-week-calendar
									:key="key"
									:week="semana"
									:open="open"
									@openCard="openCard"
									@setSelectDay="setSelectDay"
								></day-week-calendar>
							</div>
						</template>
						<!-- TODO: Todo de prueba -->

						<template v-if="tabCalendar === 3">
							<div class="container-grid-calendar container-by-day content-days mt-1">
								<p
									class="day font-small-2 day-item"
									:style="{
										color: layout.skin === 'light' ? '#656565' : '#f4f4f4',
										background: 'none',
									}"
								></p>
								<p
									v-for="(day, key) in arrayDay"
									:key="key"
									class="day font-small-2 day-item"
									:style="{
										color: layout.skin === 'light' ? '#656565' : '#f4f4f4',
										background: 'none',
									}"
								>
									{{ dayNow.getDate() }}

									{{ widthScreen < 600 ? day.name.substr(0, 3).toUpperCase() : day.name }}
								</p>
							</div>
							<day-calendar-by-day :dayNow="dayNow" :arrayCalendar="arrayCalendar" :dayFn="dayFn" />
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import DayCalendar from "@/views/amg/encaste/posturas/calendar/DayCalendar.vue";
import DayWeekCalendar from "@/views/amg/tournaments/calendar/DayWeekCalendar";
import DayCalendarByDay from "@/views/amg/tournaments/calendar/DayCalendarByDay";
import TaskItemBottom from "@/views/amg/tournaments/calendar/TaskItemBottom";
import ModalDayCalendar from "@/views/amg/tournaments/calendar/ModalDayCalendar";
import DayCalendarList from "@/views/amg/tournaments/calendar/DayCalendarList";
import lotesService from "@/services/lotes.service";
// import TournamentService from "./tournament.service.js";

export default {
	name: "CalendarPosturasView",
	components: {
		TaskItemBottom,
		DayCalendar,
		ModalDayCalendar,
		DayWeekCalendar,
		DayCalendarByDay,
		DayCalendarList,
	},
	props: {},
	data() {
		return {
			openModal: false,
			arrayStages: [
				"CREACIÓN DEL LOTE",
				"REVISIÓN INFÉRTILES/QUEMADOS",
				"REVISIÓN INFÉRTILES/QUEMADOS",
				"REVISIÓN DE NACIDOS/CINTILLO PATA",
				"VACUNACION: VIRUELA + TRIPLE AVIAR",
				"INGRESO DE CINTILLO ALA",
			],
			calendar: [],
			calendarWeek: [],
			arrayCalendar: [],
			height: screen.width < 600 ? 50 : 170,
			open: screen.width >= 600,
			monthtCurrent: null,
			yearCurrent: null,
			arrayDays: [
				{
					name: "Domingo",
					id: 0,
				},
				{
					name: "Lunes",
					id: 1,
				},
				{
					name: "Martes",
					id: 2,
				},
				{
					name: "Miercoles",
					id: 3,
				},
				{
					name: "Jueves",
					id: 4,
				},
				{
					name: "Viernes",
					id: 5,
				},
				{
					name: "Sabado",
					id: 6,
				},
			],
			arrayMonths: [
				{
					name: "Enero",
					id: 1,
				},
				{
					name: "Febrero",
					id: 2,
				},
				{
					name: "Marzo",
					id: 3,
				},
				{
					name: "Abril",
					id: 4,
				},
				{
					name: "Mayo",
					id: 5,
				},
				{
					name: "Junio",
					id: 6,
				},
				{
					name: "Julio",
					id: 7,
				},
				{
					name: "Agosto",
					id: 8,
				},
				{
					name: "Setiembre",
					id: 9,
				},
				{
					name: "Octubre",
					id: 10,
				},
				{
					name: "Noviembre",
					id: 11,
				},
				{
					name: "Diciembre",
					id: 12,
				},
			],
			arrayDay: [],

			openTabCalendar: false,
			tabCalendar: 1,
			widthScreen: screen.width,
			selectDay: {},
			calendarSelectDay: null,
			openModalTask: false,
			taskDayCalendar: null,
			dateNow: new Date(),
			tasks: [],
			idWeek: 0,
			idWeekNow: 0,
			dayIn: null,
			dayFn: null,
			arrayTasksNow: null,
			dayNow: new Date(),
			updateMonthDay: false,
		};
	},
	async created() {
		await this.initCalendar(new Date());
	},
	computed: {
		...mapState("appConfig", ["layout"]),
	},
	methods: {
		openCard(height, open) {
			this.height = height;
			this.open = open;
		},
		selectTabCalendar(id) {
			this.openTabCalendar = false;
			this.tabCalendar = id;
			// console.log('id tab: ', id)
		},
		optionsCalendar() {
			//alert("Esto es una alerta");
		},
		getDateByString(string) {
			const date = string.split("-");
			return new Date(date[0], date[1] - 1, date[2]);
		},
		setSelectDay(day, month, year) {
			const items = this.arrayCalendar.find(
				(item) => item.day == day && item.month == month && item.year == year
			);
			this.calendarSelectDay = items.data;
			//console.log('data: ', this.calendarSelectDay)
		},
		closeModal() {
			this.openModalTask = false;
		},
		selectTask(task) {
			this.openModalTask = true;
			this.taskDayCalendar = task;
		},
		async initCalendar(date) {
			this.isPreloading();
			//cal = [];
			let cal = [];
			let calendario = [];
			//this.calendar = [];
			const dateNow = date;
			const dayIdNow = dateNow.getDay();
			const dayNow = dateNow.getDate();
			const monthNow = dateNow.getMonth();
			this.monthtCurrent = monthNow + 1;
			//console.log('date: ', dateNow, monthNow)
			const yearNow = dateNow.getFullYear();
			this.yearCurrent = yearNow;
			const totalDaysNow = new Date(yearNow, monthNow + 1, 0).getDate();

			const monthPrevious = dateNow.getMonth();
			const yearPrevious = dateNow.getMonth() + 1 === 1 ? dateNow.getFullYear() - 1 : dateNow.getFullYear();
			const totalDaysPnullrevious = new Date(yearPrevious, monthPrevious, 0).getDate();
			const initIn = new Date(yearNow, monthNow, 0).getDate() - new Date(yearNow, monthNow, 1).getDay() + 1;
			const offMonthPrevious = new Date(yearNow, monthNow, 0).getDate();

			// previous month
			for (let i = initIn; i <= offMonthPrevious; i += 1) {
				cal.push({
					month: monthNow - 1 === 0 || monthNow - 1 === -1 ? 12 : monthNow,
					monthName: this.arrayMonths.find((item) => item.id === (monthNow === 0 ? 12 : monthNow)),
					year: yearPrevious,
					day: i,
					monthSelect: false,
				});
			}
			// now month
			for (let i = 1; i <= totalDaysNow; i += 1) {
				cal.push({
					monthName: this.arrayMonths.find((item) => item.id === monthNow + 1),
					month: monthNow + 1,
					year: yearNow,
					day: i,
					monthSelect: true,
				});
			}
			//next month
			const len = 35 - cal.length;
			for (let j = 0; j < len; j += 1) {
				cal.push({
					monthName: this.arrayMonths.find((item) => item.id === (monthNow + 2 === 13 ? 1 : monthNow + 2)),
					month: monthNow + 2,
					year: monthNow + 2 === 13 ? yearNow + 1 : yearNow,
					day: j + 1,
					monthSelect: false,
				});
			}

			// Dia inicial y final del mes
			this.dayIn = {
				day: cal[0].day < 10 ? `0${cal[0].day}` : cal[0].day,
				month: cal[0].month < 10 ? `0${cal[0].month}` : cal[0].month,
				year: cal[0].year,
			};
			this.dayFn = {
				day: cal[34].day < 10 ? `0${cal[34].day}` : cal[34].day,
				month: cal[34].month < 10 ? `0${cal[34].month}` : cal[34].month,
				year: cal[34].year,
			};

			const data = await lotesService.getLotesCalendar({
				dateIn: `${this.dayIn.year}-${this.dayIn.month}-${this.dayIn.day}`,
				dateFn: `${this.dayFn.year}-${this.dayFn.month}-${this.dayFn.day}`,
			});

			this.tasks = [];

			this.tasks = this.tasks.map((item) => {
				return {
					...item,
					colors: JSON.parse(item.colors),
					createdBy: JSON.parse(item.createdBy),
					fronts: item.fronts ? JSON.parse(item.fronts) : [],
					participants: item.contacts ? JSON.parse(item.contacts) : [],
					future: item.future ? item.future : false,
				};
			});

			// LLenar tareas al calendario
			cal = cal.map((item) => {
				// console.log(new Date(item.year, item.month, item.day))
				const dateComponent = new Date(item.year, item.month - 1, item.day);
				item.data = data.filter((date) => {
					const fecha = this.getDateByString(date.start_date);
					// console.log('fecha: ', fecha, date.end_date);
					if (
						dateComponent.getDate() === fecha.getDate() &&
						dateComponent.getMonth() === fecha.getMonth() &&
						dateComponent.getFullYear() === fecha.getFullYear()
					) {
						return date;
					}
				});
				return item;
			});
			this.arrayCalendar = cal;
			let semana = 1;
			let day = 0;
			for (semana; semana <= 5; semana += 1) {
				const sem = [];
				for (let item = 1; item <= 7; item += 1) {
					cal[day].idArray = day;
					sem.push(this.arrayCalendar[day]);
					day += 1;
				}
				calendario.push(sem);
			}
			this.calendar = calendario;
			//console.log('log1: ', this.arrayCalendar)
			//console.log('log2: ', this.calendar)
			// Fechas de inicio y fin del calendario en pantalla
			this.isPreloading(false);
		},
		async setDateArrow(option, date) {
			// Agregar api por fechas
			//this.isPreloading(true);
			// this.dateNow = date;
			// this.dateNow.setMonth(date.getMonth() + option);
			const dt = date;
			dt.setMonth(dt.getMonth() + option);
			//this.calendar = []

			await this.initCalendar(dt);
			//this.isPreloading(false);
		},
		async reloadCalendar() {
			if (this.tabCalendar === 1) {
				await this.initCalendar(new Date());
			}
			if (this.tabCalendar === 2) {
				this.calendar.forEach((week, index) => {
					week.forEach((day) => {
						if (
							new Date().getDate() == day.day &&
							new Date().getMonth() + 1 == day.month &&
							new Date().getFullYear() == day.year
						) {
							// console.log(
							//     "es hoy: ",
							//     new Date(),
							//     "index: ",
							//     index
							// );
							this.idWeek = index;
							this.idWeekNow = index;
						}
					});
				});

				this.calendarWeek = this.calendar.filter((item, index) => index == this.idWeek);

				//console.log("asd: ", this.calendar[0]);
			}
			if (this.tabCalendar === 3) {
				await this.updateIdDay(0, "+");
			}
		},
		async updateIdWeek(id) {
			//console.log("ZX: ", this.idWeek, id);
			if (id === "0") {
				await this.setDateArrow(0, new Date());
				this.calendarWeek = this.calendar.filter((item, index) => index == this.idWeekNow);
			} else {
				if (
					id === "-" &&
					this.idWeek == 0 &&
					this.dayIn.day == new Date(this.calendarWeek[0][0].year, this.calendarWeek[0][0].month, 1).getDate()
				) {
					await this.setDateArrow(-1, this.dateNow);
					this.idWeek = 4;
					//console.log('calendar: ', this.calendar);
					let cal = { ...this.calendar };
					//console.log("week: ", this.cal);
					this.calendarWeek = this.calendar.filter((item, index) => index == 4);
				} else if (id === "-" && this.idWeek == 0) {
					await this.setDateArrow(-1, this.dateNow);
					this.idWeek = 3;
					//console.log('calendar: ', this.calendar);
					let cal = { ...this.calendar };
					//console.log("week: ", this.cal);
					this.calendarWeek = this.calendar.filter((item, index) => index == 3);
				} else if (id === "-") {
					this.calendarWeek = this.calendar.filter((item, index) => index == this.idWeek - 1);
					this.idWeek = this.idWeek - 1;
				}

				if (
					id === "+" &&
					this.idWeek == 4 &&
					this.dayFn.day == new Date(this.calendarWeek[0][6].year, this.calendarWeek[0][6].month, 0).getDate()
				) {
					// console.log(
					//     "ultimo dia1: ",
					//     new Date(
					//         this.calendarWeek[0][6].year,
					//         this.calendarWeek[0][6].month,
					//         0
					//     ).getDate(),
					//     this.dayFn.day
					// );
					await this.setDateArrow(1, this.dateNow);
					this.idWeek = 0;
					this.calendarWeek = this.calendar.filter((item, index) => index == 0);
				} else if (id === "+" && this.idWeek == 4 && this.calendarWeek[0][6].month == this.monthtCurrent + 1) {
					// console.log(
					//     "ultimo dia2: ",
					//     new Date(
					//         this.calendarWeek[0][6].year,
					//         this.calendarWeek[0][6].month,
					//         0
					//     ).getDate()
					// );
					await this.setDateArrow(1, this.dateNow);
					this.idWeek = 1;
					this.calendarWeek = this.calendar.filter((item, index) => index == 1);
				} else if (id === "+" && this.idWeek == 4 && this.calendar[0][6].month == this.monthtCurrent) {
					//console.log("ASD");
					await this.setDateArrow(1, this.dateNow);
					this.idWeek = 0;
					this.calendarWeek = this.calendar.filter((item, index) => index == 0);
				} else if (id === "+") {
					//console.log("+ ultimo");
					this.calendarWeek = this.calendar.filter((item, index) => index == this.idWeek + 1);
					this.idWeek = this.idWeek + 1;
				}
			}
		},
		async updateIdDay(id, option) {
			const hoy = this.arrayCalendar.filter(
				(item) => item.day == this.dayNow.getDate() && item.month == this.dayNow.getMonth() + 1
			);
			//console.log("day: ", this.dayNow, hoy);
			this.dayNow = new Date(this.dateNow.setDate(this.dateNow.getDate() + id));
			//TODO: Arreglar function para solo la peticion cuando se cambia de mes
			await this.initCalendar(this.dayNow);
			// TODO arreglar update
			// if (
			//     this.dayNow.getDate() == this.dayIn.day &&
			//     this.dateNow.getMonth() + 1 == this.dayIn.month &&
			//     this.dayIn.year == this.dateNow.getFullYear() && false
			// ) {
			//     await this.setDateArrow(0, this.dayNow);
			//     console.log("cambio previous", this.calendar[0].day, this.dayNow.getDay(), this.calendar[0].day !== this.dayNow.getDay() + 1);
			//     if (this.dayNow.getDay() == 0 && this.calendar[0].day !== this.dayIn.day + 1 && this.calendar.length > 0) {
			//         await this.setDateArrow(
			//             0,
			//             new Date(
			//                 this.dayNow.setDate(this.dateNow.getDate() - 1)
			//             )
			//         );
			//         //this.dayNow =
			//     }
			//     if(this.dayNow.getDay() != 0 && this.calendar[0].day !== this.dayIn.day + 1 && this.calendar.length > 0) {
			//         await this.setDateArrow(
			//             -1,
			//             new Date(this.dayNow.setDate(this.dateNow.getDate()))
			//         );
			//     }
			// } else if (
			//     this.dayNow.getDate() == this.dayFn.day &&
			//     this.dateNow.getMonth() + 1 == this.dayFn.month &&
			//     this.dayFn.year == this.dateNow.getFullYear() && this.calendar.length > 0 && false
			// ) {
			//     console.log("cambio next", this.dateNow);
			//     if (this.dayNow.getDay() == 6) {
			//         await this.setDateArrow(
			//             0,
			//             new Date(
			//                 this.dayNow.setDate(this.dateNow.getDate() + 1)
			//             )
			//         );
			//         //this.dayNow =
			//     } else {
			//         await this.setDateArrow(
			//             +1,
			//             new Date(this.dayNow.setDate(this.dateNow.getDate()))
			//         );
			//     }
			// }

			this.arrayDay = this.arrayDays.filter((item, index) => index == this.dayNow.getDay());
		},
	},
	watch: {
		tabCalendar() {
			this.reloadCalendar();
		},
		calendarWeek() {
			//this.updateIdWeek(this.idWeek)
		},
		dayNow() {
			this.monthtCurrent = this.dayNow.getMonth() + 1;
		},
	},
};
</script>

<style lang="scss" scoped>
.container-grid-calendar {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	//margin: 2rem 0 0 0;
}
.container-by-week {
	grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
}
.container-by-day {
	grid-template-columns: 1fr 8fr;
}
.grid-semana {
	//margin-top: 0;
}
.dark-layout {
	.content-days {
		p {
			color: #f4f4f4 !important;
		}
	}
}
.content-days {
	transition: 0.2s all ease-in-out;
	margin-bottom: 0;
	border-bottom: 1px solid #e5e5e5;
	p {
		color: #656565 !important;
	}
}
.dark-layout .content-days {
	border-color: #2d3446;
}
.day-item {
	height: 30px !important;
	@media screen and (min-width: 600px) {
		font-size: 16px !important;
	}
}
.grid-calendar-tasks {
	background: #fff;
	border-radius: 10px;
	padding: 1rem;
	@media screen and (min-width: 600px) {
		padding: 1.6rem;
	}
	.item-task {
		margin-bottom: 0.8rem;
		.title {
			color: #2c2c2c;
			margin: 0;
			font-weight: 500;
		}
		.date {
			display: flex;
			align-items: center;

			p {
				margin: 0;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.circle {
	height: 12px !important;
	width: 12px !important;
	background: #d5d5d5;
	border-radius: 50%;
	margin-right: 0;
}
.dark-layout .grid-calendar-tasks {
	.item-task {
		.title {
			color: #fff;
		}
	}
}
.container-tabs-calendar {
	display: flex;

	flex-direction: column;

	@media screen and (min-width: 900px) {
		flex-direction: row;
		align-items: center;
	}
	h2 {
		width: 210px;
		color: #5c5c5c;
	}
	.icons-container {
		margin-top: 0.5rem;
		@media screen and (min-width: 900px) {
			margin-top: 0;
		}
	}
}
.calendario-lotes {
	grid-template-columns: 1fr !important;
	margin: 0.5rem;
}
.icon-calendar-smart {
	color: #181818;
	transition: 0.3s all ease-in-out;
}
.dark-layout .icon-calendar-smart {
	color: #fff;
}
.icons-select {
	color: #3594f0 !important;
}
.item-tab-day {
	position: relative;
	cursor: pointer;
	.day-number {
		position: absolute;
		top: -4%;
		left: 50%;
		transform: translateX(-50%);
		font-size: 13px !important;
	}
}

.grid-icons-arrow {
	// margin-top: 0.5rem;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media screen and (min-width: 600px) {
		justify-content: flex-start;
	}
	.icon-arrow-calendar {
		cursor: pointer;
		background: #7367f0;
		color: #fff;
		margin: 0 0.2rem;
		border-radius: 0.2rem;
		padding: 0.2rem;
	}
}

.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}
.container-select-type-calendar {
	border: 1px solid #b3b3b3;
	border-radius: 6px;
	padding: 0.3rem 1.2rem;
	cursor: pointer;
	margin-right: 0.5rem;
	transition: 0.3s all ease-in-out;
	box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
	&:hover {
		background: #f0f0f0;
	}
	@media screen and (min-width: 600px) {
		margin-right: 1rem;
	}
}
.dark-layout .container-select-type-calendar {
	&:hover {
		background: #454a5a;
	}
}
.calendar-tab-icon {
	margin-left: 8px;
	@media screen and (min-width: 600px) {
		margin-left: 14px;
	}
}
.container-calendar-total {
	.list-total {
		display: none;
	}
	@media screen and (min-width: 2400px) {
		display: grid;
		grid-template-columns: 8fr 2fr;
		gap: 4rem;
		.list-total {
			display: flex;
			.container-calendar-list {
				@media screen and (min-width: 900px) {
					grid-template-columns: 3fr 9fr;
				}
			}
		}
	}
}
.container-calendar-view {
	display: grid;
	grid-template-columns: 1fr;
	gap: 2rem;
	@media screen and (min-width: 900px) {
		// gap: 2rem;
		grid-template-columns: 1fr 5fr;
	}
}
.container-name-stage {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;

	.circle-stages {
		margin-bottom: 0;
		// width: 24px !important;
		height: 24px !important;
		min-width: 24px !important;
		background: #7367f0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		border-radius: 50%;
		font-weight: bolder;
	}
	span {
		margin-left: 0.5rem;
	}
}
</style>
