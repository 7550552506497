<template>
    <div class=" mt-2">
        <template v-if="times.length > 0">
            <DayCalendarListItem
                v-for="(card, index) in times"
                :key="index"
                :card="card"
            />
        </template>

        <div v-else class="container-calendar-list">
            <div>
                <div
                    class="tournament-item-task"
                >
                    <div
                        class="circle-day"
                        :style="{
                            background:
                                layout.skin === 'light'
                                    ? `red`
                                    : `red`
                        }"
                    />
                    <div class="">
                        <p
                            class="m-0 title"
                            
                        >
                            Sin fechas para este mes
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import DayCalendarListItem from "./DayCalendarListItem";
import { mapState } from "vuex";

export default {
    name: "DayCalendarList",
    components: {
        DayCalendarListItem
    },
    props: {
        dayNow: {
            type: Date
        },
        tasks: {
            type: Array
        }
    },
    data() {
        return {
            times: []
        };
    },
    created() {
        //console.log("tasks1: ", this.tasks);
        //result = cars.reduce((h, a) => object.assign(h, { [a.make]:( h[a.make] || [] ).concat(a) }), {})
        this.init();
    },
    computed: {
        ...mapState("appConfig", ["layout"])
    },
    methods: {
        init() {
            this.times = this.tasks.reduce(
                (h, a) =>
                    Object.assign(h, { [a.date]: (h[a.date] || []).concat(a) }),
                {}
            );
            this.times = Object.keys(this.times).map(key => [
                key,
                this.times[key]
            ]);
            this.times = this.times.map(item => {
                const date = item[0].split("-");
                const aux = {
                    date: new Date(date[0], date[1], date[2]),
                    data: item[1]
                };
                return aux;
            });
        }
    },
    watch: {
        tasks() {
            this.init();
        }
    }
};
</script>

<style lang="scss" scoped>
.card {
    overflow: hidden;
}
.tournament-item-task{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    @media screen and (min-width: 600px) {
        padding: 1.5rem 3rem;
    }
}
</style>
