<template>
	<div>
		<b-row class="invoice-preview">
			<b-col cols="12" xl="12" md="12">
				<b-card no-body class="invoice-preview-card">
					<b-card-body class="invoice-padding">
						<b-row class="invoice-spacing">
							<b-col cols="12">
								<b-card no-body class="mb-1">
									<div class="table-responsive">
										<b-table
											:api-url="'/api/lote/get-detail'"
											ref="refLoteDetail"
											class="position-relative"
											:items="myProvider"
											:stacked="currentBreakPoint === 'sm' || currentBreakPoint === 'xs'"
											sticky-header="75vh"
											:fields="arrayColumns"
											primary-key="id"
											table-class="text-nowrap"
											responsive
											show-empty
											:busy.sync="isBusy"
											empty-filtered-text="No hay registros que coincidan con tu búsqueda."
											empty-text="No hay registros que mostrar."
											:sort-by.sync="sortBy"
											:sort-desc.sync="sortDesc"
											:current-page="current_page"
											:per-page="perpage"
											:filter="search_input"
										>
											<template #table-busy>
												<div class="text-center text-primary my-2">
													<b-spinner class="align-middle"></b-spinner>
													<strong>Cargando...</strong>
												</div>
											</template>

											<template #cell(fertility)="{ item }">
												<div class="text-center text-muted">
													<strong>{{ item.fertility }} %</strong>
												</div>
											</template>
											<template #cell(efectivity)="{ item }">
												<div class="text-center text-muted">
													<strong>{{ item.efectivity }} %</strong>
												</div>
											</template>
											<template #cell(madrilla_alias)="data">
												<span>
													{{ data.item.madrilla_alias }}
												</span>
												<br />
												<b-badge variant="secondary" class="pr-3 pl-3">
													{{ data.item.placa_madrilla }}
												</b-badge>
											</template>
											<template #cell(padrillo_alias)="data">
												<span>
													{{ data.item.padrillo_alias }}
												</span>
												<br />
												<b-badge variant="secondary" class="pr-3 pl-3">
													{{ data.item.placa_padrillo }}
												</b-badge>
											</template>
											<template #cell(cintillo)="data">
												<div class="card cintillo-card">
													<span
														style="font-size: 12px; line-height: 1.7"
														v-if="data.item.cintillo_color"
														>{{ data.item.chickenleg_description }}
														{{ data.item.cintillo_description }}
													</span>
													<span class="fa-squaress" v-if="data.item.cintillo_color"
														><feather-icon
															:style="`color: ${data.item.cintillo_color}; background: ${data.item.cintillo_color}; border-radius: 3px;`"
															icon="SquareIcon"
															size="20"
													/></span>
													<span
														style="font-size: 12px; line-height: 1.7"
														v-if="data.item.second_color_color"
														>{{ data.item.second_color_description }}
													</span>
													<span class="fa-squaress" v-if="data.item.second_color_color">
														<feather-icon
															v-if="data.item.second_color_color"
															:style="`color: ${data.item.second_color_color}; background: ${data.item.second_color_color}; border-radius: 3px; margin-bottom: 5px;`"
															icon="SquareIcon"
															size="20"
														/>
													</span>
												</div>
											</template>
										</b-table>
									</div>
								</b-card>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import axios from "@/axios";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import moment from "moment";
export default {
	mounted() {
		this.today = moment().format("YYYY-MM-DD");
	},
	directives: {
		Ripple,
	},
	props: {
		loteId: Number,
	},
	components: {
		AppCollapse,
		AppCollapseItem,
	},
	data() {
		return {
			sortBy: "madrilla_alias",
			sortDesc: true,
			arrayColumns: [
				{
					key: "postura_code",
					label: "Postura",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "madrilla_alias",
					label: "Madrilla",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "padrillo_alias",
					label: "Padrillo",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "quantity_eggs",
					label: "ING",
					class: "text-center font-weight-bolder",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
					visible: true,
				},
				{
					key: "quantity_infertile_eggs",
					label: "INF",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
					visible: true,
				},
				{
					key: "quantity_burned_eggs",
					label: "QUE",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
					visible: true,
				},
				{
					key: "quantity_brooder",
					label: "INC",
					class: "text-center font-weight-bolder",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
					visible: true,
				},
				{
					key: "fertility",
					label: "FER",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
				},
				{
					key: "quantity_born_eggs",
					label: "NAC",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
					visible: true,
				},
				{
					key: "quantity_no_born_eggs",
					label: "NO N",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
					visible: true,
				},
				{
					key: "quantity_alive",
					label: "VIV",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
					visible: true,
				},
				{
					key: "quantity_deads_all",
					label: "MUE",
					class: "text-center",
					sortable: true,
					thClass: "px-50",
					tdClass: "px-50",
					visible: true,
				},
				{
					key: "efectivity",
					label: "EFE",
					class: "text-center",
					thClass: "px-50",
					tdClass: "px-50",
					sortable: true,
				},
				{
					key: "cintillo",
					label: "Cintillo",
					class: "text-center",
				},
			],
			search_input: "",
			orderby: this.dato2 == null ? 3 : this.dato2,
			order: this.dato1 == null ? "desc" : this.dato1,
			start_page: "",
			end_page: "",
			total_data: "",
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: "",
			dato1: "",
			dato2: "",
			loadingData: false,
			isBusy: false,
			perPageOptions: [10, 25, 50, 100],
			arrayDetail: [],
			data: [
				{
					color: "light-primary",
					customClass: "mb-2 mb-xl-0",
					icon: "BarChart2Icon",
					subtitle: "Huevos",
					title: 0,
				},
				{
					color: "light-danger",
					customClass: "mb-2 mb-xl-0",
					icon: "TrendingDownIcon",
					subtitle: "Infértiles",
					title: 0,
				},
				{
					color: "light-danger",
					customClass: "mb-2 mb-xl-0",
					icon: "SunsetIcon",
					subtitle: "Quemados",
					title: 0,
				},
				{
					color: "light-primary",
					customClass: "mb-2 mb-xl-0",
					icon: "InboxIcon",
					subtitle: "Incubadora",
					title: 0,
				},
				{
					color: "light-success",
					customClass: "mb-2 mb-xl-0",
					icon: "TrendingUpIcon",
					subtitle: "Vivos",
					title: 0,
				},
				{
					color: "light-info",
					customClass: "mb-2 mb-xl-0",
					icon: "ActivityIcon",
					subtitle: "Muertos",
					title: 0,
				},
			],
			today: null,
		};
	},
	computed: {
		loteCode() {
			if (this.arrayDetail.length > 0) {
				return this.arrayDetail[0].code;
			}
		},
		totalHuevos: function () {
			let sum = 0;
			for (let i = 0; i < this.arrayDetail.length; i++) {
				sum += this.arrayDetail[i].quantity_eggs ? parseFloat(this.arrayDetail[i].quantity_eggs) : 0;
			}
			return sum;
		},
		totalHuevosInfertiles: function () {
			let sum = 0;
			for (let i = 0; i < this.arrayDetail.length; i++) {
				sum += this.arrayDetail[i].quantity_infertile_eggs
					? parseFloat(this.arrayDetail[i].quantity_infertile_eggs)
					: 0;
			}
			return sum;
		},
		totalHuevosQuemados: function () {
			let sum = 0;
			for (let i = 0; i < this.arrayDetail.length; i++) {
				sum += this.arrayDetail[i].quantity_burned_eggs
					? parseFloat(this.arrayDetail[i].quantity_burned_eggs)
					: 0;
			}
			return sum;
		},
		totalHuevosIncubadora: function () {
			let sum = 0;
			for (let i = 0; i < this.arrayDetail.length; i++) {
				sum += this.arrayDetail[i].quantity_brooder ? parseFloat(this.arrayDetail[i].quantity_brooder) : 0;
			}
			return sum;
		},
		totalHuevosVivos: function () {
			let sum = 0;
			for (let i = 0; i < this.arrayDetail.length; i++) {
				sum += this.arrayDetail[i].quantity_born_eggs ? parseFloat(this.arrayDetail[i].quantity_born_eggs) : 0;
			}
			return sum;
		},
		totalHuevosMuertos: function () {
			let sum = 0;
			for (let i = 0; i < this.arrayDetail.length; i++) {
				sum += this.arrayDetail[i].quantity_dead_eggs ? parseFloat(this.arrayDetail[i].quantity_dead_eggs) : 0;
			}
			return sum;
		},
	},
	methods: {
		myProvider(ctx) {
			const promise = axios.post(
				// "/some/url?page=" + ctx.currentPage + "&size=" + ctx.perPage
				`${ctx.apiUrl}?page=${ctx.currentPage}`,
				{
					orderby: ctx.sortDesc == 1 ? "desc" : "asc",
					order: ctx.sortBy == "" ? "madrilla_alias" : ctx.sortBy,
					campo: ctx.filter,
					perpage: ctx.perPage,
					lote_id: this.loteId,
				}
			);

			// Must return a promise that resolves to an array of items
			return promise.then((data) => {
				// Pluck the array of items off our axios response
				this.arrayDetail = data.data;
				this.today = this.$options.filters.myGlobalDay(this.arrayDetail[0].chicks_dob);
				const items = data.data;

				// Must return an array of items or an empty array if an error occurred
				return items || [];
			});
		},
		closeModal() {
			this.modalReasonController = false;
			this.$refs.refLoteDetail.refresh();
		},
	},
	watch: {
		arrayDetail() {
			this.data[0].title = this.totalHuevos;
			this.data[1].title = this.totalHuevosInfertiles;
			this.data[2].title = this.totalHuevosQuemados;
			this.data[3].title = this.totalHuevosIncubadora;
			this.data[4].title = this.totalHuevosVivos;
			this.data[5].title = this.totalHuevosMuertos;
		},
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
td.div {
	width: 100% !important;
}
@media (max-width: 960px) {
	.column-table {
		display: flex;
		flex-direction: column;
	}
}
.cintillo-card {
	width: 100% !important;
	max-width: 150px !important;
	margin: 0 auto !important;
	flex-wrap: wrap !important;
	white-space: initial !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
