<template>
  <div class="item-task" @click="$emit('selectTask', task)">
    <p class="title">
      Torneo | {{ task.title }}
    </p>
    <div class="date">
      <div class="circle" :style="{ background: `${task.colors.color}` }" />
      <p v-if="date" class="font-small-3">
        {{ month ? month.name : null }}, {{ numberDay ? numberDay : null }} {{ day ? day.name : null }} {{ task.time }}
      </p>
      <p v-else class="font-small-3">
         {{ task.time }}
      </p>
      
    </div>
  </div>
</template>

<script>

export default {
  name: 'TaskItemBottom',
  components: {

  },
  props: {
    task: {
      type: Object,
      default: {},
    },
    date: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      arrayDays: [
        {
          name: 'Domingo',
          id: 0,
        },
        {
          name: 'Lunes',
          id: 1,
        },
        {
          name: 'Martes',
          id: 2,
        },
        {
          name: 'Miercoles',
          id: 3,
        },
        {
          name: 'Jueves',
          id: 4,
        },
        {
          name: 'Viernes',
          id: 5,
        },
        {
          name: 'Sabado',
          id: 6,
        },
      ],
      arrayMonths: [
        {
          name: 'Enero',
          id: 1,
        },
        {
          name: 'Febrero',
          id: 2,
        },
        {
          name: 'Marzo',
          id: 3,
        },
        {
          name: 'Abril',
          id: 4,
        },
        {
          name: 'Mayo',
          id: 5,
        },
        {
          name: 'Junio',
          id: 6,
        },
        {
          name: 'Julio',
          id: 7,
        },
        {
          name: 'Agosto',
          id: 8,
        },
        {
          name: 'Setiembre',
          id: 9,
        },
        {
          name: 'Octubre',
          id: 10,
        },
        {
          name: 'Noviembre',
          id: 11,
        },
        {
          name: 'Diciembre',
          id: 12,
        },
      ],
      month: null,
      day: null,
      numberDay: null,
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const date = new Date(this.task.date)
      date.setDate(date.getDate() + 1)
      this.month = this.arrayDays.find(item => item.id === date.getDay())
      this.day = this.arrayMonths.find(item => item.id === date.getMonth() + 1)
      this.numberDay = date.getDate()
    }
  },
  watch: {
    task() {
      this.init();
    }
  },
}
</script>

<style lang="scss">
.circle{
  margin-top: 0 !important;
}
</style>
