<template>
	<div :id="`day-${day.idArray + 1}`" ref="id" :style="{ position: 'relative' }">
		<div
			:class="
				dayNow.getDate() === day.day && dayNow.getMonth() + 1 === day.monthName.id
					? 'container-day-hoy-visible'
					: 'container-day-hoy'
			"
		>
			<Transition name="fade">
				<div
					class="day"
					:class="[
						day.monthName.id === new Date().getMonth() + 1 ? 'month-current' : '',
						day.monthSelect ? 'es-hoy' : '',
						day.idArray < 28 ? 'day-border' : 'day-border border-bottom-none',
						day.idArray === 6 ||
						day.idArray === 13 ||
						day.idArray === 20 ||
						day.idArray === 27 ||
						day.idArray === 34
							? 'border-right-none'
							: '',
						day.data.length > 3 ? 'day-scroll' : '',
						dayNow.getDate() === day.day && dayNow.getMonth() + 1 === day.monthName.id
					? 'border-bottom-none'
					: ''
					]"
				>
					<div class="container-day-extend">
						<p
							class="tournament-item-day"
							:class="
								dayNow.getDate() === day.day && dayNow.getMonth() + 1 === day.monthName.id
									? 'es-hoy-extend'
									: ''
							"
						>
							{{ day.day }}
						</p>
						<div
							v-for="(card, key) in day.data"
							:key="key"
							class="tournament-item-task"
							v-b-tooltip.hover
							:title="card.future ? 'Proxima final' : card.title"
						>
							<div
								class="container-text"
								:style="{
									background:
										layout.skin === 'light' ? `${card.colors.light}` : `${card.colors.dark}`,
								}"
							>
								<p class="text-light font-weight-bold">
									{{ card.code }}
								</p>
							</div>

							<div
								class="container-stage"
								:style="{
									background:
										layout.skin === 'light' ? `${card.colors.light}` : `${card.colors.dark}`,
								}"
							>
								<span class="text-light font-weight-bold">{{ card.order }}</span>
							</div>
						</div>
					</div>
				</div>
			</Transition>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import PosturasByIdLote from "@/views/amg/specimens/PosturasByIdLote.vue";
import LotesDetail from "../../lotes/LotesDetail.vue";

export default {
	name: "DayCalendar",
	components: {
		PosturasByIdLote,
		LotesDetail,
	},
	props: {
		day: {
			type: Object,
			default: () => {},
		},
		height: {
			type: Number,
			default: 70,
		},
		open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			id: null,
			scrollY: 0,
			heightC: 30,
			dayNow: new Date(),
			winScreen: screen.width,
			openModalTask: false,
			taskView: null,
			openModalLote: false,
			titleModal: "",
			loteItem: null,
			modalTotalPosturas: {
				title: "",
				content: "",
				show: false,
				idLote: null,
			},
		};
	},
	computed: {
		...mapState("appConfig", ["layout"]),
	},
	methods: {
		start(e) {
			if (e.deltaY < -65 && screen.width < 600) {
				this.$emit("openCard", 50, false);
			}
		},
		end(e) {
			if (e.deltaY > 60) {
				this.$emit("openCard", 124, true);
			}
		},
		closeModal() {
			this.taskView = null;
			this.openModalTask = false;
		},
	},
};
</script>

<style lang="scss">
.day {
	cursor: pointer;
	text-align: center;
	padding-top: 0.5rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	touch-action: auto !important;
	// overflow: hidden;
	height: 200px;
	z-index: 2000;

	@media screen and (min-width: 600px) {
		//padding-top: .5rem;
	}
	.circle-mobile {
		width: 6px !important;
		height: 6px !important;
	}
	.container-day {
		height: 38px;
		width: 38px;
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		p {
			transition: 0.3s all ease-in-out;
			color: #cacaca;
			padding: 0;
			margin: 0 0 2px 0;
		}
	}
	.container-day-extend {
		padding: 0 0.01rem;
		@media screen and (min-width: 600px) {
			padding: 0;
		}

		.tournament-item-day {
			margin-bottom: 0.5rem !important;
			font-size: 12.6px;
			height: 28px;
			width: 28px;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 0;
			color: #cacaca;
			@media screen and (min-width: 600px) {
				font-size: 14px;
			}
		}
		.tournament-item-task {
			background: transparent;
			border-radius: 3px;
			margin: 0 0 .8rem 0;
			display: flex;
			flex-direction: column !important;
			align-items: center;
			justify-content: center;
			padding: 0.3px 0.6px;
			transition: 0.3s all ease-in-out;
			//   height: 15px;
			position: relative;
			@media screen and (min-width: 900px) {
				margin: 0 0 0.3rem 0;
				padding: 1px 2px;
				height: auto;
				flex-direction: row !important;
			}
			p {
				margin-bottom: 0;
				margin-left: 0;
				//margin-top: -.15rem;
				color: #6e6b7b;
				// width: 24px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 8px;

				@media screen and (min-width: 400px) {
					//   width: 30px;
					padding: 0.5px 1px;
				}
				@media screen and (min-width: 600px) {
					//   width: 54px;

					text-align: left;
					font-size: 11px;
				}
				@media screen and (min-width: 800px) {
					//   width: 74px;
					margin-left: 0.2rem;
				}
				@media screen and (min-width: 1000px) {
					//   width: 96px;
				}

				@media screen and (min-width: 768px) {
					text-align: left;
					font-size: 11px;
				}
				@media screen and (min-width: 900px) {
					padding: 1px 3px;

					text-align: left;
					font-size: 12px;
				}
				@media screen and (min-width: 1300px) {
					padding: 2px 4px;
					//   width: 140px;

					text-align: left;
					font-size: 14px;
				}
				@media screen and (min-width: 1600px) {
					padding: 2px 4px;
					//   width: 180px;

					text-align: left;
					font-size: 14px;
				}
				span {
					font-weight: bold;
				}
			}
			.final {
				position: absolute;
				top: -9px;
				right: -8px;
				height: 20px;
				width: 20px;
				// background: rgb(57, 255, 20);
				color: #efb100;
				img {
					height: 12px;
					width: 12px;
					object-fit: cover;
					@media screen and (min-width: 900px) {
						top: -8px;
						right: -5px;
						height: 20px;
						width: 20px;
					}
				}
			}
			.container-text {
				display: flex;
				align-items: center;
				justify-content: center;
				// height: 25px;
				padding: 0 0.05rem;
				border-radius: 4px;
				@media screen and (min-width: 900px) {
					height: 25px;
					padding: 0 0.2rem;
				}
				.circle-day {
					display: none;
					@media screen and (min-width: 900px) {
						display: block;
					}
				}
			}
			.container-stage {
				// height: 20px;
				// width: 20px;
				// min-height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				// margin-left: 0.5rem;
				margin-top: 0.3rem;
				@media screen and (min-width: 600px) {
					margin-top: 0;
					margin-left: 0.3rem;
				}
				span {
					height: 20px;
					min-height: 20px;
					width: 20px;
					font-size: 12px;
					padding: 0 !important;
					margin: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				@media screen and (min-width: 600px) {
					height: 25px;
					width: 25px;
					span {
						font-size: 14px;
					}
				}
			}
		}
		.more-text {
			color: #549de1;
			margin: -0.3rem 0 0 0;
			text-align: left;
			font-weight: 600;
		}
	}
	min-height: 30px;
	transition: 0.2s all ease-in-out;
}
.day-scroll {
	overflow-y: scroll;
}

.day-scroll::-webkit-scrollbar {
	width: 2px !important;
	background: #d5d1fb !important;
	@media screen and (min-width: 600px) {
		width: 4px !important;
	}
}

.day-scroll::-webkit-scrollbar-thumb {
	background: #7367f0 !important;
	border-radius: 6px !important;
}

.dark-layout .day {
	.container-day-extend,
	.container-day {
		.tournament-item-day {
			color: #5c616f;
		}
	}
}

.day-border {
	transition: 0.2s all ease-in-out;
	@media screen and (min-width: 600px) {
		border-bottom: #dadce0 0.6px solid;
		border-right: #dadce0 0.6px solid;
	}
}
.dark-layout .day-border {
	border-color: #2d3446;
}
.border-bottom-none {
	border-bottom: none !important;
}
.border-right-none {
	border-right: none !important;
}

.month-current {
	//height: 200px !important;
	.container-day,
	.container-day-extend {
		.tournament-item-day {
			color: #0b0f19;
		}
	}
	.es-hoy-extend {
		color: #fff;
	}
}
.dark-layout .month-current {
	.container-day,
	.container-day-extend {
		.tournament-item-day {
			color: #fff;
		}
	}
}
.es-hoy {
	.container-day {
		background: #0090f0 !important;
		p {
			color: #fff !important;
			font-weight: 600 !important;
		}
	}
}
.es-hoy-extend {
	background: rgba(115, 103, 240, 0.5) !important;
	font-weight: 600 !important;
	color: #5c5c5c !important;
	height: 22px;
	width: 22px;
	border-radius: 50%;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	@media screen and (min-width: 600px) {
		height: 28px;
		width: 28px;
	}
}

.circle-day {
	margin-left: 0.2rem;
	height: 4px;
	width: 4px;
	background: #d5d5d5;
	border-radius: 50%;
	@media screen and (min-width: 800px) {
		width: 6px !important;
		height: 6px !important;
		margin-left: 0.3rem;
	}
}
.dark-layout .circle-day {
	background: #aaaaaa;
}
.dark-layout .day {
	transition: 0.3s all ease-in-out;
	//background: #5c616f;
}
.dark-layout .month-current {
	//background: #0d111d;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
.text-count-posturas {
	margin: 0 0.2rem;
}
.container-btn-view-lote {
	button {
		width: 100px;
	}
}
.container-day-hoy {
	// background: red;
	// border: 3px solid #7367f0 !important;
	// background: #F3ECFE;
}
.container-day-hoy-visible {
	position: absolute;
	width: 100%;
	height: 100%;
	border: 2px solid #7367f0 !important;
	background: #F3ECFE;
	// border-bottom: none !important;
}
</style>
