<template>
    <div>
        <div class="container-by-day">
            <div class="hours ">
                <div
                    v-for="(time, index) in times"
                    :key="index"
                    class="item-hour-week text-center"
                >
                    <span class=" ">
                        {{ time.start }}
                    </span>
                    <span class="line">-</span>
                    <span class=" ">
                        {{ time.end }}
                    </span>
                </div>
            </div>
            <div>
                <div
                    :class="[]"
                    class="day"
                    v-for="(time, index) in tasks"
                    :key="index"
                >
                    <div class="container-day-extend">
                        <div
                            v-for="(card, key) in time.tasks"
                            :key="key"
                            class="tournament-item-task"
                            :style="{
                                background:
                                    layout.skin === 'light'
                                        ? `${card.colors.color1}`
                                        : `${card.colors.color}`
                            }"
                            @click="selectTask(card)"
                        >
                            <div
                                class="circle-day"
                                :style="{
                                    background:
                                        layout.skin === 'light'
                                            ? `${card.colors.color}`
                                            : `${card.colors.color1}`
                                }"
                            />
                            <span
                                class=""
                                :style="{
                                    color:
                                        layout.skin === 'light'
                                            ? `${card.colors.color}`
                                            : `${card.colors.color1}`
                                }"
                            >
                                {{ card.title }}
                            </span>
                        </div>
                    </div>
                </div>

                <modal-day-calendar
                    :task-view="taskView"
                    :open-modal-task="openModalTask"
                    @closeModal="closeModal"
                    v-if="taskView !== null"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import ModalDayCalendar from "@/views/amg/tournaments/calendar/ModalDayCalendar";

export default {
    name: "DayCalendarByDay",
    components: {
        ModalDayCalendar
    },
    props: {
        arrayCalendar: {
            type: Array
        },
        dayNow: {
            type: Date
        },
        dayFn: {
            type: Object
        }
    },
    data() {
        return {
            times: [],
            tasks: [],
            taskView: null,
            openModalTask: false
        };
    },
    computed: {
        ...mapState("appConfig", ["layout"])
    },
    created() {
        if(this.arrayCalendar.length > 0) {
            this.initDay();
        }
    },
    methods: {
        initDay() {
            let tim = []
            let tar = []
            tim = [];
            tar = [];
            for (let i = 16; i < 48; i++) {
                tim.push({
                    start:
                        i % 2 == 0
                            ? `${
                                  Math.floor(i / 2) < 10
                                      ? `0${Math.floor(i / 2)}`
                                      : Math.floor(i / 2)
                              }:00`
                            : `${
                                  Math.floor(i / 2) < 10
                                      ? `0${Math.floor(i / 2)}`
                                      : Math.floor(i / 2)
                              }:${i % 2 == 0 ? 0 : 3}0`,
                    end:
                        i % 2 != 0
                            ? `${
                                  Math.floor((i + 1) / 2) < 10
                                      ? `0${Math.floor((i + 1) / 2)}`
                                      : Math.floor((i + 1) / 2)
                              }:00`
                            : `${
                                  Math.floor(i / 2) < 10
                                      ? `0${Math.floor(i / 2)}`
                                      : Math.floor(i / 2)
                              }:${i % 2 == 0 ? 3 : 0}0`,
                    hour: i % 2 == 0 ? Math.floor(i / 2) : Math.floor(i / 2),
                    minute: i % 2 == 0 ? 0 : 1,
                    active: false
                });
            }
            //console.log('tar: ', this.arrayCalendar);

            let tareas = this.arrayCalendar.find(
                item => {
                    console.log('item: ', item, (item.day == this.dayNow.getDate() && item.month == this.dayNow.getMonth() + 1 && item.year == this.dayNow.getFullYear()));
                    console.log('day: ', this.dayNow);
                    if((item.day == this.dayNow.getDate() && item.month == this.dayNow.getMonth() + 1 && item.year == this.dayNow.getFullYear())){
                        return item
                    }
                }
            );
            
            //console.log('tareas: ', this.arrayCalendar.length, this.dayNow ,tareas);
            if(tareas == undefined) {
                tareas.data = []
            }
            this.times = tim;
            tar = tim.map(item => {
                const tasks = tareas.data.filter(task => {
                    //console.log('QWE: ', parseInt(task.time2) == 30 ? 1 : 0, item.minute);
                    if (
                        parseInt(task.time1) == item.hour &&
                        (parseInt(task.time2) == 30 ? 1 : 0) == item.minute
                    ) {
                        return task;
                    }
                });
                return {
                    ...item,
                    tasks
                };
            });
            this.tasks = tar
            //console.log("task: ", this.tasks);
        },
        selectTask(task) {
            console.log("task: ", task);
            this.openModalTask = true;
            this.taskView = task;
            //console.log(task)
        },
        closeModal() {
            this.taskView = null;
            this.openModalTask = false;
        }
    },
    watch: {
        dayNow() {
           if(this.dayNow.getDate() == this.dayFn.day) {
               console.log('ultimo dia');
               this.initDay();
           }
        },
        arrayCalendar() {
            this.initDay()
        }

    }
};
</script>

<style lang="scss" scoped>
.container-by-day {
    display: grid;
}
.item-hour-week {
    color: rgb(101, 101, 101);
    background: transparent;
    padding: 0.3rem 0;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 900px) {
        flex-direction: row;
    }
    span{
        font-size: 10px;
        @media screen and (min-width: 600px) {
            font-size: 12px;
        }
        @media screen and (min-width: 900px) {
            font-size: 14px;
        }
    }
    .line {
        display: none;
        @media screen and (min-width: 900px) {
            display: flex;
            margin: 0 0.2rem;
        }
    }
}
.day {
    height: 40px;
}
.day .container-day-extend .tournament-item-task span {
    width: auto;
    margin: 0 .2rem;
    //margin-top: -.2rem !important;
}
.dark-layout .hours span{
    color: #cccccc;
}
</style>
