<template>
  <div class="container-item-postura">
    <div class="container-postura-lote items-container">
      <p class="code">{{ item.code }}</p>
      <div class="item-specimen-posturas">
        <img :src="item.padrillo.thumb" alt="" class="image-specimen" />
        <div class="skeletor"></div>
        <div class="name">
          <p @click="clickImageSpecimen(item.padrillo)" class="cursor-pointer">{{ item.padrillo.alias }}</p>
          <p class="alias" @click="linkSpecimen(item.padrillo.id)">{{ item.padrillo.plate }}</p>
        </div>
      </div>

      <div class="item-specimen-posturas">
        <img :src="item.madrilla.thumb" alt="" class="image-specimen" />
        <div class="skeletor"></div>
        <div class="name">
          <p @click="clickImageSpecimen(item.madrilla)" class="cursor-pointer">{{ item.madrilla.alias }}</p>
          <p class="alias" @click="linkSpecimen(item.madrilla.id)">{{ item.madrilla.plate }}</p>
        </div>
      </div>

      <div class="numbers">
        <p>{{ sumEggs(item, "quantity_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_infertile_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_burned_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_born_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_no_born_eggs") }}</p>

        <p>{{ sumEggs(item, "quantity_dead_eggs") }}</p>
      </div>

      <p class="status">{{ item.status }}</p>

      <p class="status">{{ item.postura_date }}</p>

      <!-- <div class="icon-plusv2" @click="show = !show">
        <FeatherIcon icon="ArrowDownCircleIcon" size="20" />
        <p class="mb-0">Ver lotes</p>
      </div> -->
    </div>

    <SlidersImageVideo
      :url="clickImage.url"
      :type="clickImage.type"
      :text="clickImage.text"
      :text_url="clickImage.text_url"
      :open="clickImage.open"
      v-if="clickImage.open"
      @refreshSlider="refresh"
    />
  </div>
</template>

<script>
import SlidersImageVideo from '../header-bookmarks/SlidersImageVideo.vue';

export default {
  name: "PosturasByIdLoteItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    SlidersImageVideo,
  },
  data() {
    return {
      show: false,
      clickImage: {
        url: null,
        type: 'image',
        text: null,
        text_url: null,
        open: false,
      },
    };
  },
  computed: {
    sumEggs: () => (item, key) => {
      return item.posturas.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue[key];
      }, 0);
    },
  },
  mounted() {
    console.log("sum");
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
    linkSpecimen(id) {
      window.open(`/ejemplares/detalle/${id}`, "_blank");
    },
     refresh() {
      this.clickImage.open = false;
      this.clickImage.text_url = null;
      this.clickImage.type = null;
      this.clickImage.text = item.plate;
      this.clickImage.url = null;
    },
    clickImageSpecimen(item){
      // console.log('QW', item);
      this.clickImage.open = true;
      this.clickImage.text_url = `ejemplares/detalle/${item.id}`;
      this.clickImage.type = 'image';
      this.clickImage.text = `${item.plate} ${item.alias}`;
      this.clickImage.url = item.image;
    },
  },
};
</script>

<style lang="scss">
.container-item-postura {
  // border-bottom: 1.5px solid #eff3f5;
  margin-bottom: 1rem;
}
.lote-code {
  grid-column: 2 / 4;
  text-align: center;
  font-weight: bold;
}
.content-postura-lote {
  height: 0;
  min-height: 0;
  overflow: hidden;
  transition: 1s all ease-in-out;
  opacity: 0;
}
.content-postura-lote-active {
  height: 0px;
  // min-height: 200px;
  overflow: visible;
  opacity: 1;
}
.numbers {
  p {
    // color: black;
  }
}
.border {
  border-bottom: 1.5px solid #eff3f5;
  // padding: 6rem 0;
}
.estado-cintillo-header {
  margin-top: 0.2rem;
}
.icon-plusv2 {
  display: flex;
  flex-direction: column;
  p {
    margin-top: 0.5rem;
  }
}
</style>
