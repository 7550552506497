var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-by-day"},[_c('div',{staticClass:"hours "},_vm._l((_vm.times),function(time,index){return _c('div',{key:index,staticClass:"item-hour-week text-center"},[_c('span',{staticClass:" "},[_vm._v(" "+_vm._s(time.start)+" ")]),_c('span',{staticClass:"line"},[_vm._v("-")]),_c('span',{staticClass:" "},[_vm._v(" "+_vm._s(time.end)+" ")])])}),0),_c('div',[_vm._l((_vm.tasks),function(time,index){return _c('div',{key:index,staticClass:"day",class:[]},[_c('div',{staticClass:"container-day-extend"},_vm._l((time.tasks),function(card,key){return _c('div',{key:key,staticClass:"tournament-item-task",style:({
                            background:
                                _vm.layout.skin === 'light'
                                    ? ("" + (card.colors.color1))
                                    : ("" + (card.colors.color))
                        }),on:{"click":function($event){return _vm.selectTask(card)}}},[_c('div',{staticClass:"circle-day",style:({
                                background:
                                    _vm.layout.skin === 'light'
                                        ? ("" + (card.colors.color))
                                        : ("" + (card.colors.color1))
                            })}),_c('span',{style:({
                                color:
                                    _vm.layout.skin === 'light'
                                        ? ("" + (card.colors.color))
                                        : ("" + (card.colors.color1))
                            })},[_vm._v(" "+_vm._s(card.title)+" ")])])}),0)])}),(_vm.taskView !== null)?_c('modal-day-calendar',{attrs:{"task-view":_vm.taskView,"open-modal-task":_vm.openModalTask},on:{"closeModal":_vm.closeModal}}):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }