// import { amgApi } from "@/service/axios";

import axios from "@/axios"

class TournamentService {
	async savePlace(body) {
		const data = await axios.post(`/api/tournament/save-place`, body)
		return data
	}
	async getPlaces() {
		const data = await axios.get(`/api/tournament/get-places`)
		return data
	}

	async getTourmanetCounter({ yearIn }) {
		const { data } = await axios.get(`/api/tournament/get-counter?yearIn=${yearIn}`)
		return data
	}

	async saveTournament(body) {
		const data = await axios.post(`/api/tournament/save`, body)
		return data
	}
	async savePrizeToTournamentPlace(body) {
		const data = await axios.post(`/api/tournament/save-prize-to-tournament-place`, body)
		return data
	}
	async updatePrizeToTournamentPlace(body) {
		const data = await axios.post(`/api/tournament/update-prize-to-tournament-place`, body)
		return data
	}
	async saveTournamentStageSpecimenResult(body) {
		const data = await axios.post(`/api/tournament/save-tournament-stage-specimen-result`, body)
		return data
	}
	async saveTournamentStage(body) {
		const data = await axios.post(`/api/tournament/save-tournament-stage`, body)
		return data
	}
	async getTournamentStages(body) {
		const data = await axios.post(`/api/tournament/get-stages-by-tournament`, body)
		return data
	}
	async savePreparador(body) {
		const data = await axios.post(`/api/tournament/save-preparador`, body)
		return data
	}
	async getPreparadores() {
		const data = await axios.get(`/api/tournament/get-preparadores`)
		return data
	}
	async deleteTournamentStage(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-stage`, body)
		return data
	}
	async deleteTournamentStageDate(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-stage-date`, body)
		return data
	}
	async setTournamentStageClasificado(body) {
		const data = await axios.post(`/api/tournament/set-tournament-stage-clasificado`, body)
		return data
	}
	async createTournamentStage(body) {
		const data = await axios.post(`/api/tournament/create-tournament-stage`, body)
		return data
	}
	async addRoostersToTournamentStage(body) {
		const data = await axios.post(`/api/tournament/add-roosters-to-tournament-stage`, body)
		return data
	}
	async getSpecimensUsedByFront(param) {
		const data = await axios.get(`/api/tournament/get-specimens-used-by-front/${param}`)
		return data
	}
	async getRazorsUsedByTournament() {
		const data = await axios.get(`/api/tournament/get-razors-used-by-tournament`)
		return data
	}
	async updateTournamentFlyer(body) {
		const data = await axios.post(`/api/tournament/update-tournament-flyer`, body)
		return data
	}
	async getTournamentAwards(body) {
		const data = await axios.post(`/api/tournament/get-tournament-awards`, body)
		return data
	}
	async deleteTournamentAward(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-award`, body)
		return data
	}
	async getTournamentFiles(body) {
		const data = await axios.post(`/api/tournament/get-tournament-files`, body)
		return data
	}
	async saveTournamentFiles(body) {
		const data = await axios.post(`/api/tournament/save-tournament-files`, body)
		return data
	}
	async deleteTournamentFile(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-file`, body)
		return data
	}
	async getTournamentStageDates(body) {
		const data = await axios.post(`/api/tournament/get-tournament-stage-dates`, body)
		return data
	}
	async saveTournamentStageDates(body) {
		const data = await axios.post(`/api/tournament/save-tournament-stage-dates`, body)
		return data
	}
	async updateTournamentStageDate(body) {
		const data = await axios.post(`/api/tournament/update-tournament-stage-date`, body)
		return data
	}
	async saveTournamentFront(body) {
		const data = await axios.post(`/api/tournament/save-tournament-front`, body)
		return data
	}
	async getFront(body) {
		const data = await axios.post(`/api/tournament/get-front`, body)
		return data
	}
	async getTournamentDate(body) {
		const data = await axios.post(`/api/tournament/get-tournament-date`, body)
		return data
	}
	async deleteTournamentFront(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-front`, body)
		return data
	}
	async classifyDate(body) {
		const data = await axios.post(`/api/tournament/classify-date`, body)
		return data
	}
	async setDateToTournamentDate(body) {
		const data = await axios.post(`/api/tournament/set-date-to-tournament-date`, body)
		return data
	}
	async setColiseumToTournamentDate(body) {
		const data = await axios.post(`/api/tournament/set-coliseum-to-tournament-date`, body)
		return data
	}
	async setCityToTournamentDate(body) {
		const data = await axios.post(`/api/tournament/set-city-to-tournament-date`, body)
		return data
	}
	async deleteTournamentDate(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-date`, body)
		return data
	}
	async setTournamentDateInformation(body) {
		const data = await axios.post(`/api/tournament/set-tournament-date-information`, body)
		return data
	}
	async editTournamentFrontSpecimen(body) {
		const data = await axios.post(`/api/tournament/edit-tournament-front-specimen`, body)
		return data
	}
	async deleteTournamentFrontSpecimen(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-front-specimen`, body)
		return data
	}
	async getTournamentRecognitions(body) {
		const data = await axios.post(`/api/tournament/get-tournament-recognitions`, body)
		return data
	}
	async deleteTournamentFlyer(body) {
		const data = await axios.post(`/api/tournament/delete-tournament-flyer`, body)
		return data
	}
	async saveTournamentFlyers(body) {
		const { data } = await axios.post(`/api/tournament/save-tournament-flyers`, body)
		return data
	}
	async saveMaterialAward(body) {
		const { data } = await axios.post(`/api/tournament/save-material-award`, body)
		return data
	}
	async updateTournamentFront(body) {
		const data = await axios.post(`/api/tournament/update-tournament-front`, body)
		return data
	}
	async getAwardsAndRecognitions(type) {
		const { data } = await axios.get(`/api/tournament/get-awards-and-recognitions/${type}`)
		return data
	}

	async saveTournamentResponsable(body) {
		const { data } = await axios.post(`/api/tournament/save-tournament-responsable`, body)
		return data
	}

	async getTournamentResponsables() {
		const { data } = await axios.get(`/api/tournament/get-tournament-responsables`)
		return data
	}
	async getSumRoosterQuantityByStatus() {
		const { data } = await axios.get(`/api/tournament/get-sum-rooster-quantity-by-status`)
		return data
	}
	async changeStatusTournament(body) {
		const { data } = await axios.post(`/api/tournament/change-status-tournament`, body)
		return data
	}
	async updateFrontData(body) {
		const { data } = await axios.post(`/api/tournament/update-front-data`, body)
		return data
	}
	async saveTypeFight(body) {
		const { data } = await axios.post(`/api/tournament/save-type-fight`, body)
		return data
	}
	async getCalendarTournament(body) {
		const { data } = await axios.post(`/api/tournament/get-calendar`, body)
		return data
	}
	async addBankAccountsTournament(body) {
		const { data } = await axios.post(`/api/tournament/add-bank-accounts-tournament`, body)
		return data
	}
	async getCalendarDateFronts(body) {
		const { data } = await axios.post(`/api/tournament/get-fronts-calendar`, body)
		return data
	}
	async deleteBankAccountTournament(body) {
		const { data } = await axios.post(`/api/tournament/delete-bank-account-tournament`, body)
		return data
	}
	async updateBankAccountTournament(body) {
		const { data } = await axios.post(`/api/tournament/update-bank-account-tournament`, body)
		return data
	}
	async addTournamentFrontParticipants(body) {
		const { data } = await axios.post(`/api/tournament/add-tournament-front-participants`, body)
		return data
	}
	async deleteParticipantTournamentFront(body) {
		const { data } = await axios.post(`/api/tournament/delete-participant-tournament-front`, body)
		return data
	}
	async getParticipants() {
		const { data } = await axios.get(`/api/tournament/get-participants`)
		return data
	}
	async registerParticipants(body) {
		const { data } = await axios.post(`/api/tournament/register-participants`, body)
		return data
	}
	async registerOthersMotive(body) {
		const { data } = await axios.post(`/api/tournament/register-others-motives`, body)
		return data
	}
	async registerTravelExpenses(body) {
		const { data } = await axios.post(`/api/tournament/register-travel-expenses`, body)
		return data
	}
	async getTravelExpensesByStageDate(id) {
		const { data } = await axios.get(`/api/tournament/get-travel-expenses-by-stage-date/${id}`)
		return data
	}

	async getOthersMotive() {
		const { data } = await axios.get(`/api/tournament/get-others-motives`)
		return data
	}
	// No usar, oslo para generar colores
	async generateColors(body) {
		const { data } = await axios.get(`/api/tournament/generate-colors`)
		return data
	}
	async generateColors(body) {
		const { data } = await axios.get(`/api/tournament/generate-colors`)
		return data
	}

	async calendarFutureDates(body) {
		const { data } = await axios.post(`/api/tournament/calendar-future-dates`, body)
		return data
	}

	async getParticipant(body) {
		const { data } = await axios.get(`/api/tournament/events/participants`, { params: body })
		return data
	}

	async setParticipantFromDiagram(id,body){
		const {data} = await axios.post(`/api/tournament/events/participants/${id}/set-participant`,{participant:body})
		return data
	}

	async getbyDate(id) {
		const { data } = await axios.get(`/api/tournament/events/participants/${id}/show`)
		return data
	}

	async getParticipantsTournamentDiagram(id) {
		const { data } = await axios.get(`/api/tournament/events/participants/${id}/confrontations`)
		return data
	}

	async changePositionOfParticipant(id, participants) {
		const data = await axios.put(`/api/tournament/events/participants/${id}/change-position-of-participants`, {
			...participants,
		})
		return data
	}

	async selectWinner(id, participant) {
		const data = await axios.put(`/api/tournament/events/participants/${id}/select-winner`, { ...participant })
		return data
	}

	async registerParticipantMassive(body) {
		const { data } = await axios.post(`/api/tournament/events/participants/store`, body)
		return data
	}

	async registerParticipantEvents(body) {
		const { data } = await axios.post(`/api/tournament/events/participants/add-participant-to-event`, body)
		return data
	}

	async changeStage(id, body) {
		const data = await axios.post(`/api/tournament/events/participants/${id}/change-end-stage`, body)
		return data
	}

	async deleteParticipant(id, body) {
		const data = await axios.post(`/api/tournament/events/participants/${id}/remove-participant-from-diagram`, body)
		return data
	}


	//get-details-date-by-calendar
	//tournament/generate-colors'
	//calendar-future-dates
}
export default new TournamentService()
