<template>
  <validation-observer
    tag="div"
    ref="form"
    class="add-roosters-to-tournament-modal"
  >
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group
          label="Tipo de clasificación"
          :label-for="`classification-type`"
        >
          <b-form-select
            v-model="classification_type"
            :options="optionsClassificationType"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" v-if="classification_type == 1">
        <b-form-group label="Inscripción" label-for="precio">
          <b-input-group size="md" id="precio">
            <template #prepend>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="stage.currency_id == 1 ? '$ ' : 'S/'"
                :variant="
                  layout.skin == 'dark'
                    ? 'outline-gray-1000'
                    : 'outline-white-1000'
                "
              >
                <b-dropdown-item @click="setStageCurrency(1)">
                  $
                </b-dropdown-item>
                <b-dropdown-item @click="setStageCurrency(2)">
                  S/
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <b-form-input
              type="number"
              class="text-right"
              v-model="stage.registration_cost"
              placeholder="0"
              @blur="fixedCost(stage)"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group label="Tipo" label-for="type">
          <validation-provider rules="required" v-slot="{ errors }">
            <b-form-select
              v-model="tournament_type_id"
              :options="tournamentTypes"
              value-field="id"
              text-field="name"
              :class="{ 'border-danger rounded': errors[0] }"
            ></b-form-select>
            <span style="color: red">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group
          label="Cantidad de Gallos del frente"
          label-for="rooster-quantity"
        >
          <b-form-select
            v-model="rooster_quantity"
            :options="roosterQuantityOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal
      id="modal-careador"
      ok-only
      ok-title="Agregar"
      modal-class="modal-primary"
      centered
      title="Agregar careador"
      v-model="addCareadorModal"
      @ok.prevent="saveCareador"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Nombre" label-for="careadorName">
            <b-form-input id="careadorName" v-model="careadorObject.name" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-amarrador"
      ok-only
      ok-title="Agregar"
      modal-class="modal-primary"
      centered
      title="Agregar amarrador"
      v-model="addAmarradorModal"
      @ok.prevent="saveAmarrador"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Nombre" label-for="amarradorName">
            <b-form-input id="amarradorName" v-model="amarradorObject.name" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-preparador"
      ok-only
      ok-title="Agregar"
      modal-class="modal-primary"
      centered
      title="Agregar preparador"
      @ok.prevent="savePreparador"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Nombre" label-for="preparadorName">
            <b-form-input id="preparadorName" v-model="preparadorObject.name" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="modal-razor"
      ok-only
      ok-title="Agregar"
      modal-class="modal-primary"
      centered
      title="Agregar navaja"
      v-model="addRazorModal"
      @ok.prevent="saveRazors"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Numeración" label-for="razorNumber">
            <b-form-input id="razorNumber" v-model="razorObject.number" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TournamentService from "./tournament.service";
import { Money } from "v-money";
export default {
  props: {
    tournamentObject: {
      type: Object,
    },
    dateId: {
      type: Number,
    },
    stageObject: {
      type: Object,
      default: () => ({
        tournament_stage_id: null,
        tournament_stage_date_id: null,
        classification_type: 1,
        currency_id: null,
        registration_cost: null,
        rooster_quantity: 0,
        rooster_quantity_to_play: 0,
        created_by: null,
        roosters: [],
      }),
    },
  },
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    Money,
  },
  computed: {
    layout() {
      return this.$store.getters["appConfig/layout"];
    },
  },
  async mounted() {
    await this.getTournamentTypes();
    this.stage = { ...this.stageObject };
  },
  data() {
    return {
      isPrestado: false,
      stage: {},
      tournamentTypes: [],
      tournamentStages: [],
      amarradores: [],
      careadores: [],
      preparadores: [],
      razors: [],
      roosters: [],
      roostersList: [],
      amarradorObject: { name: null },
      careadorObject: { name: null },
      razorObject: { number: null },
      preparadorObject: { name: null, id: null },
      selectedRooster: null,
      addAmarradorModal: false,
      addCareadorModal: false,
      addRazorModal: false,
      hours: [],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      usedRoosters: [],
      usedRazors: [],
      optionsClassificationType: [
        { id: 1, name: "Inscripción" },
        { id: 2, name: "Clasificación" },
      ],
      rooster_quantity: 0,
      rooster_quantity_to_play: 0,
      roosterQuantityOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      classification_type: 1,
      tournament_type_id: 1,
    };
  },
  methods: {
    async getSpecimensUsedByTournament() {
      try {
        const response = await TournamentService.getSpecimensUsedByTournament();
        this.usedRoosters = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    getTournamentTypes() {
      axios.get("/api/tournament/get-tournaments-types").then((response) => {
        this.tournamentTypes = response.data;
      });
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["plate", "alias"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    async addRoostersToTournamentStage() {
      const succesfull = await this.$refs.form.validate();
      if (succesfull) {
        try {
          this.stage.roosters = this.roostersList;
          this.stage.created_by = this.currentUser.user_id;
          this.stage.tournament_stage_date_id = this.dateId;
          this.stage.rooster_quantity = this.rooster_quantity;
          this.stage.classification_type = this.classification_type;
          this.stage.tournament_type_id = this.tournament_type_id;
          const data = await TournamentService.saveTournamentFront(this.stage);
          this.closeModal();
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Etapa actualizada!",
                icon: "CheckIcon",
                text: "Etapa actualizada correctamente",
                variant: "success",
              },
            },
            {
              position: "top-right",
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    },
    closeModal() {
      this.$emit("closeModal", "addFronts");
    },
    deleteRooster(index, rooster) {
      this.roostersList.splice(index, 1);
      this.roosters.push(rooster);
    },
    setStageCurrency(currency, rooster) {
      this.$set(rooster, "currency_id", currency);
    },
    saveAmarrador() {
      axios
        .post("/api/save-amarrador", {
          nameamarrador: this.amarradorObject.name,
        })
        .then((response) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Amarrador agregado",
                icon: "CheckIcon",
                text: "Amarrador agregado correctamente",
                variant: "success",
              },
            },
            {
              position: "top-right",
            }
          );
          this.addAmarradorModal = false;
          this.getAmarradores();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveCareador() {
      axios
        .post("/api/save-careador", {
          namecareador: this.careadorObject.name,
        })
        .then((response) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Careador agregado",
                icon: "CheckIcon",
                text: "Careador agregado correctamente",
                variant: "success",
              },
            },
            {
              position: "top-right",
            }
          );
          this.addCareadorModal = false;
          this.getCareadores();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async savePreparador() {
      try {
        await TournamentService.savePreparador({
          name: this.preparadorObject.name,
          created_by: this.currentUser.user_id,
          preparador_id: this.preparadorObject.id,
        });
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Preparador agregado",
              icon: "CheckIcon",
              text: "Preparador agregado correctamente",
              variant: "success",
            },
          },
          {
            position: "top-right",
          }
        );
        this.$bvModal.hide("modal-preparador");
        this.getPreparadores();
      } catch (error) {
        console.error(error);
      }
    },
    fixedCost(front) {
      if (front.registration_cost) {
        front.registration_cost = Number(front.registration_cost).toFixed(2);
      }
    },
    saveRazors() {
      axios
        .post("/api/tournament/save-razors", {
          number: this.razorObject.number,
          created_by: this.currentUser.user_id,
        })
        .then((response) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Navaja agregada",
                icon: "CheckIcon",
                text: "Navaja agregada correctamente",
                variant: "success",
              },
            },
            {
              position: "top-right",
            }
          );
          this.addRazorModal = false;
          this.getRazors();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    selectedRooster(newVal, oldVal) {
      if (newVal && this.roostersList.length < this.rooster_quantity) {
        newVal.careador_id = 0;
        newVal.amarrador_id = 0;
        newVal.razor_id = 0;
        this.$set(newVal, "registration_cost", 0);
        this.$set(newVal, "currency_id", 2);
        this.$set(newVal, "classification_type", 1);
        this.roostersList.push(newVal);
        for (let i = 0; i < this.roosters.length; i++) {
          if (this.roosters[i].id == newVal.id) {
            this.roosters.splice(i, 1);
            this.selectedRooster = null;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.add-roosters-to-tournament-modal {
  .v-select {
    &:not(.vs--open) {
      .vs__selected + .vs__search {
        height: 0;
        margin: 0;
      }
    }

    .vs__selected-options {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
