<template>
    <div class="container-week-linear">
        <!-- <div
            class="linear-now"
            :style="{ marginTop: `calc( ${minutesNow}% 0px)` }"
        ></div> -->
        <div class="container-grid-calendar container-by-week grid-semana">
            <p class="tournament-item-day"></p>
            <p
                v-for="(day, index) in week"
                :key="index"
                class="tournament-item-day"
                :class="
                    dayNow.getDate() === day.day &&
                    dayNow.getMonth() + 1 === day.monthName.id
                        ? 'es-hoy-extend'
                        : ''
                "
            >
                {{ day.day }}
            </p>
        </div>
        <div class="container-grid-calendar container-by-week grid-semana">
            <div class="hours">
                <div
                    v-for="(time, index) in times"
                    :key="index"
                    class="item-hour-week text-center"
                >
                    <span class="">
                        {{ time.start }}
                    </span>
                    <span class="line">-</span>
                    <span class="">
                        {{ time.end }}
                    </span>
                </div>
            </div>

            <day-week-calendar-item
                v-for="(day, index) in week"
                :key="index"
                :day="day"
                :times="times"
                :week="week"
            />
        </div>
    </div>
</template>

<script>
import DayWeekCalendarItem from "./DayWeekCalendarItem";

export default {
    name: "DayWeekCalendar",
    components: { DayWeekCalendarItem },
    props: {
        week: {
            type: Array,
            default: []
        },
        height: {
            type: Number,
            default: 70
        },
        open: {
            type: Boolean
        }
    },
    data() {
        return {
            tasks: [],
            times: [],
            dayNow: new Date(),
            minutesNow: 0,
            hourNow: null,
            percentageHours: 0,
            interval: null,
            intervalID: 0,
            Polling: null
        };
    },

    created() {
        //setInterval(this.seconds, 1000)
        //this.ReckonTime();

        for (let i = 16; i < 48; i++) {
            this.times.push({
                start:
                    i % 2 == 0
                        ? `${
                              Math.floor(i / 2) < 10
                                  ? `0${Math.floor(i / 2)}`
                                  : Math.floor(i / 2)
                          }:00`
                        : `${
                              Math.floor(i / 2) < 10
                                  ? `0${Math.floor(i / 2)}`
                                  : Math.floor(i / 2)
                          }:${i % 2 == 0 ? 0 : 3}0`,
                end:
                    i % 2 != 0
                        ? `${
                              Math.floor((i + 1) / 2) < 10
                                  ? `0${Math.floor((i + 1) / 2)}`
                                  : Math.floor((i + 1) / 2)
                          }:00`
                        : `${
                              Math.floor(i / 2) < 10
                                  ? `0${Math.floor(i / 2)}`
                                  : Math.floor(i / 2)
                          }:${i % 2 == 0 ? 3 : 0}0`,
                hour: i % 2 == 0 ? Math.floor(i / 2) : Math.floor(i / 2),
                minute: i % 2 == 0 ? 0 : 1,
                active: false
            });
        }
        //console.log("times: ", this.week);
    },
    methods: {
        seconds() {
            //console.log('time: ', new Date().getHours(), new Date().getMinutes());
            //this.minutesNow = (new Date().getHours() - 8)/ 0.148011100832562 + new Date().getMinutes() * 0.112604166666667;
            this.minutesNow = 25;
            // console.log(
            //     "seconds: ",
            //     new Date().getHours(),
            //     new Date().getMinutes(),
            //     this.minutesNow
            // );
            //this.minutesNow = 108.1;
        },
        ReckonTime() {
            // this.Polling = setInterval(() => {
            //     //console.log('log');
            // }, 1000)
        }
    },
    watch: {},
    beforeDestroy() {
        //clearInterval(this.Polling);
    }
};
</script>

<style scoped lang="scss">
.item-hour-week {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgb(101, 101, 101);
    background: transparent;
    padding: 0.3rem 0;
    @media screen and (min-width: 900px) {
        flex-direction: row;
    }
    span {
        font-size: 10px;
        @media screen and (min-width: 600px) {
            font-size: 12px;
        }
        @media screen and (min-width: 900px) {
            font-size: 14px;
        }
    }
    .line {
        display: none;
        @media screen and (min-width: 900px) {
            display: flex;
            margin: 0 0.2rem;
        }
    }
}
.hours {
    .item-hour-week {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
    }
}
.tournament-item-day {
    text-align: center;
    margin: 0.5rem auto;
}
.container-week-linear {
    position: relative;

    .linear-now {
        position: absolute;
        top: 42px;
        left: 0;
        right: 0;
        height: 1px;
        background: blue;
    }
}
.dark-layout .hours span{
    color: #cccccc;
}
</style>
