var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"id",style:({ position: 'relative' }),attrs:{"id":("day-" + (_vm.day.idArray + 1))}},[_c('div',{class:_vm.dayNow.getDate() === _vm.day.day && _vm.dayNow.getMonth() + 1 === _vm.day.monthName.id
				? 'container-day-hoy-visible'
				: 'container-day-hoy'},[_c('Transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"day",class:[
					_vm.day.monthName.id === new Date().getMonth() + 1 ? 'month-current' : '',
					_vm.day.monthSelect ? 'es-hoy' : '',
					_vm.day.idArray < 28 ? 'day-border' : 'day-border border-bottom-none',
					_vm.day.idArray === 6 ||
					_vm.day.idArray === 13 ||
					_vm.day.idArray === 20 ||
					_vm.day.idArray === 27 ||
					_vm.day.idArray === 34
						? 'border-right-none'
						: '',
					_vm.day.data.length > 3 ? 'day-scroll' : '',
					_vm.dayNow.getDate() === _vm.day.day && _vm.dayNow.getMonth() + 1 === _vm.day.monthName.id
				? 'border-bottom-none'
				: ''
				]},[_c('div',{staticClass:"container-day-extend"},[_c('p',{staticClass:"tournament-item-day",class:_vm.dayNow.getDate() === _vm.day.day && _vm.dayNow.getMonth() + 1 === _vm.day.monthName.id
								? 'es-hoy-extend'
								: ''},[_vm._v(" "+_vm._s(_vm.day.day)+" ")]),_vm._l((_vm.day.data),function(card,key){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:key,staticClass:"tournament-item-task",attrs:{"title":card.future ? 'Proxima final' : card.title}},[_c('div',{staticClass:"container-text",style:({
								background:
									_vm.layout.skin === 'light' ? ("" + (card.colors.light)) : ("" + (card.colors.dark)),
							})},[_c('p',{staticClass:"text-light font-weight-bold"},[_vm._v(" "+_vm._s(card.code)+" ")])]),_c('div',{staticClass:"container-stage",style:({
								background:
									_vm.layout.skin === 'light' ? ("" + (card.colors.light)) : ("" + (card.colors.dark)),
							})},[_c('span',{staticClass:"text-light font-weight-bold"},[_vm._v(_vm._s(card.order))])])])})],2)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }