<template>
  <validation-observer
    tag="div"
    ref="form"
    class="add-roosters-to-tournament-modal"
  >
    <b-row class="mb-1 justify-content-center">
      <b-col cols="12" md="2">
        <b-form-group label="Cantidad del Frente" label-for="rooster-quantity">
          <b-input
            id="rooster-quantity"
            type="number"
            v-model="frontRoosterQuantityExactly"
            readonly
            class="text-center"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="2">
        <b-form-group
          label="Cantidad por llevar"
          label-for="rooster-selected-quantity"
        >
          <b-input
            id="rooster-selecected-quantity"
            type="number"
            v-model="frontRoosterQuantityToCarry"
            readonly
            class="text-center"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="2">
        <b-form-group
          label="Cantidad Faltante"
          label-for="rooster-selected-quantity"
        >
          <b-input
            id="rooster-selecected-quantity"
            type="number"
            v-model="roosterToPick"
            readonly
            class="text-center"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="5">
        <b-form-group label="Elegir gallos" label-for="rooster">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="selectedRooster"
            multiple
            ref="roosterSelection"
            :options="roosters"
            id="rooster"
            :clearable="false"
            label="plate"
            :reduce="(option) => option"
            transition=""
            :selectable="() => selectedRooster.length < roosterToPick"
            :filter="fuseSearch"
            :disabled="!roosterToPick"
            :placeholder="
              roosterToPick
                ? 'Seleccione gallos'
                : 'Gallos por llevar completos'
            "
          >
            <template #selected-option="{ alias, plate }">
              {{ alias }} / ({{ plate }})
            </template>
            <template v-slot:option="option">
              {{ option.alias }} / ({{ option.plate }})
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col col class="d-flex align-items-center">
        <b-button
          variant="outline-primary"
          v-b-tooltip.hover.top="'Agregar Gallos'"
          @click="addRoosters"
          :disabled="!selectedRooster.length"
        >
			ASIGNAR GALLOS
        </b-button>
      </b-col>
    </b-row>
    <template v-for="(rooster, index) in roostersList">
      <b-row :key="index" class="justify-content-center">
        <b-col cols="12" md="2">
          <b-form-group label="Gallo" label-for="gallo_peleador">
            <b-form-input
              id="gallo_peleador"
              disabled
              :value="
                rooster.alias
                  ? rooster.alias
                  : 'sin alias' + ' / ' + rooster.plate
              "
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="2">
          <b-form-group :label-for="`amarrador-${index}`">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <span>Amarrador</span>
              </div>
            </template>
            <v-select
              :id="`amarrador-${index}`"
              label="name"
              :options="optionsAmarrador"
              :reduce="(option) => option.id"
              v-model="rooster.amarrador_id"
              transition=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group :label-for="`careador-${index}`">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <span>Careador</span>
              </div>
            </template>
            <v-select
              :id="`careador-${index}`"
              label="name"
              :options="optionsCareador"
              :reduce="(option) => option.id"
              v-model="rooster.careador_id"
              transition=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group :label-for="`preparador-${index}`">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <span>Preparador</span>
              </div>
            </template>
            <v-select
              :id="`preparador-${index}`"
              label="name"
              :options="optionsPreparador"
              :reduce="(option) => option.id"
              v-model="rooster.preparador_id"
              transition=""
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-form-group :label-for="`razor-${index}`">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <span>Navaja</span>
                <span class="clickable" @click="addRazorModal = true">
                  <feather-icon icon="PlusIcon" class="text-primary" />
                </span>
              </div>
            </template>
            <v-select
              :id="`razor-${index}`"
              label="number"
              :clearable="false"
              :options="razors"
              :reduce="(option) => option.id"
              transition=""
              v-model="rooster.razor_id"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="deleteRooster(index, rooster)"
            size="sm"
          >
            <span><feather-icon icon="XIcon" /></span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>
    </template>

    <b-modal
      id="modal-razor"
      ok-only
      ok-title="Agregar"
      modal-class="modal-primary"
      centered
      title="Agregar navaja"
      v-model="addRazorModal"
      @ok.prevent="saveRazors"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Numeración" label-for="razorNumber">
            <b-form-input id="razorNumber" v-model="razorObject.number" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </validation-observer>
</template>

<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TournamentService from "./tournament.service";
import { Money } from "v-money";
export default {
  name: 'AddRoosterToTournamentStage',
  components: {
    Money,
  },
  props: {
    tournamentId: {
      type: Number,
    },
    tournamentStatus: {
      type: String,
    },
    tournamentFrontId: {
      type: Number,
    },
    frontRoosterQuantityToCarry: {
      type: Number,
    },
    frontRoosterQuantityExactly: {
      type: Number,
    },
    frontRoosterQuantityRegistered: {
      type: Number,
    },
    front: {
      type: Object,
    },
    optionsCareador: {
      type: Array,
    },
    optionsAmarrador: {
      type: Array,
    },
    optionsPreparador: { 
		type: Array 
	},
    stageObject: {
      type: Object,
      default: () => ({
        tournament_stage_id: null,
        tournament_front_id: null,
        classification_type: null,
        currency_id: null,
        registration_cost: null,
        created_by: null,
        roosters: [],
      }),
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    layout() {
      return this.$store.getters["appConfig/layout"];
    },
    roosterToPick() {
      return (
        this.frontRoosterQuantityToCarry -
        this.frontRoosterQuantityRegistered -
        this.roostersList.length
      );
    },
  },
  async mounted() {
    await this.getSpecimensUsedByFront();
    await this.getRazorsUsedByTournament();
    await this.getRoosters();
    this.getRazors();
    this.stage = { ...this.stageObject };
    this.$emit("changeScrollableFalse");
  },
  data() {
    return {
      isPrestado: false,
      stage: {},
      tournamentTypes: [],
      tournamentStages: [],
      amarradores: [],
      careadores: [],
      preparadores: [],
      razors: [],
      roosters: [],
      roostersList: [],
      amarradorObject: { name: null },
      careadorObject: { name: null },
      razorObject: { number: null },
      preparadorObject: { name: null, id: null },
      selectedRooster: [],
      addAmarradorModal: false,
      addCareadorModal: false,
      addRazorModal: false,
      hours: [],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      usedRoosters: [],
      usedRazors: [],
      optionsClassificationType: [
        { id: 1, name: "Inscripción" },
        { id: 2, name: "Clasificación" },
      ],
      roostersInputs: [],
    };
  },
  methods: {
    getRazors() {
      axios.get("/api/tournament/get-razors").then((response) => {
        this.razors = response.data;
      });
    },
    async getSpecimensUsedByFront() {
      try {
        const response = await TournamentService.getSpecimensUsedByFront(
          this.tournamentFrontId
        );
        this.usedRoosters = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getRazorsUsedByTournament() {
      try {
        const response = await TournamentService.getRazorsUsedByTournament();
        this.usedRazors = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getRoosters() {
      const data = await axios.get("/api/specimens/get-gallos");
      let roosters = data.data;
      this.roosters = roosters.filter((rooster) => {
        //devolver gallos que no esten en array usedRoosters por propiedad id
        return !this.usedRoosters.some((usedRooster) => {
          return usedRooster.specimen_id === rooster.id;
        });
      });
    },
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["plate", "alias"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    async addRoostersToTournamentStage() {
		if(!this.roostersList.length) {
			return this.showInfoSwal({ title: 'Warning!', text: 'Aun no ha agregado ningun gallo'});
		}
        const succesfull = await this.$refs.form.validate();
        if (succesfull) {
          try {
            this.isPreloading();

            let front = {
              roosters: this.roostersList,
              created_by: this.currentUser.user_id,
              tournament_front_id: this.tournamentFrontId,
              tournament_id: this.tournamentId,
              tournament_status: this.tournamentStatus,
            };
            await TournamentService.addRoostersToTournamentStage(front);
            this.closeModal();

            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Frente actualizada!",
                  icon: "CheckIcon",
                  text: "Frente actualizada correctamente",
                  variant: "success",
                },
              },
              {
                position: "top-right",
              }
            );
            this.isPreloading(false);
          } catch (error) {
            console.error(error);
          }
      }
    },
    closeModal() {
      this.$emit("closeModal", null);
    },
    deleteRooster(index, rooster) {
      this.roostersList.splice(index, 1);
      this.roosters.push(rooster);
    },
    addRoosters() {
      this.selectedRooster.forEach((rooster) => {
        rooster.careador_id = this.front.careador_id ?? 0;
        rooster.amarrador_id = this.front.amarrador_id ?? 0;
        rooster.preparador_id = rooster.preparador_id ?? 0;
        rooster.razor_id = 0;
        this.roosters = this.roosters.filter((roosterPrincipal) => {
          return roosterPrincipal.id !== rooster.id;
        });
        this.roostersList.push(rooster);
      });

      this.selectedRooster = [];
    },
    setStageCurrency(currency) {
      stage.currency_id = currency;
    },

    saveRazors() {
      axios
        .post("/api/tournament/save-razors", {
          number: this.razorObject.number,
          created_by: this.currentUser.user_id,
        })
        .then((response) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Navaja agregada",
                icon: "CheckIcon",
                text: "Navaja agregada correctamente",
                variant: "success",
              },
            },
            {
              position: "top-right",
            }
          );
          this.addRazorModal = false;
          this.getRazors();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    selectedRooster(newVal) {
      if (newVal && newVal.length) {
        this.$nextTick(() => {
          const input = this.$refs.roosterSelection.$el.querySelector("input");
          input.focus();
        });
      }
    },
    roostersList(newVal) {
      if (newVal.length > 3) {
        this.$emit("changeScrollable");
      }
      if (newVal.length <= 3) {
        this.$emit("changeScrollableFalse");
      }
    },
  },
};
</script>

<style lang="scss">
.add-roosters-to-tournament-modal {
  .v-select {
    &:not(.vs--open) {
      .vs__selected + .vs__search {
        height: 0;
        margin: 0;
      }
    }

    .vs__selected-options {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
